import { axios } from 'technical/network';
import { IDeviceDetailedV1Response } from 'common-active-invest-supervision/dist/src/business/device/api/v1';
import logger from 'technical/logger';

export async function createArtifact(
  file: File,
  informations: string,
): Promise<void> {
  const formData = new FormData();
  formData.append('file', file);
  formData.append('informations', informations);

  const response = await axios.post('/artifact', formData, {
    headers: { 'Content-Type': 'multipart/form-data' },
  });

  if (response.status !== 201 && response.data.message) {
    logger.error(response.data.message);
    if (response.status === 409) {
      throw new Error('already-exists');
    }
    throw new Error(response.data.message);
  }
}

export async function getDevices({
  deviceTypeId,
  limit = 100,
}: {
  deviceTypeId: string;
  limit?: number;
}): Promise<{ items: IDeviceDetailedV1Response[] }> {
  try {
    const response = await axios.get<{ items: IDeviceDetailedV1Response[] }>(
      `devices?limit=${limit}`,
      { params: { deviceTypeId } },
    );

    return response.data;
  } catch (e) {
    logger.error(e);
    throw new Error('Unable to retreive devices');
  }
}
