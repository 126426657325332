import validate from 'validate.js';
import { CompanyAddValues } from '../../add/form';
import {
  REQUIRED,
  EMAIL,
  TRequired,
  TEmail,
} from '../../../../../technical/validation/rules';
import i18n from 'i18next';

interface Constraints {
  name: TRequired;
  email?: TEmail;
}

export const companyAddValidation = (values: CompanyAddValues) => {
  const constraints: Constraints = {
    name: REQUIRED(i18n.t('company.add.form.name.required')),
  };

  if (values.email) {
    constraints.email = EMAIL(i18n.t('company.add.form.email.invalidFormat'));
  }

  return validate(values, constraints);
};
