import classnames from 'classnames';
import { ErrorMessage, FieldProps } from 'formik';
import React from 'react';
import { ErrorField } from 'ui/form/error';
import styles from './index.module.scss';

type IProps = Omit<
  React.InputHTMLAttributes<HTMLInputElement> & FieldProps,
  'form'
>;

export const InputText = ({
  field,
  type = 'text',
  className,
  ...props
}: IProps) => (
  <div>
    <input
      className={classnames(styles.inputText, className)}
      type={type}
      {...field}
      {...props}
    />
    <ErrorMessage name={field.name} component={ErrorField}></ErrorMessage>
  </div>
);

export const StandaloneInputText = ({
  className,
  ...props
}: React.InputHTMLAttributes<HTMLInputElement>) => (
  <div className={styles.container}>
    <input
      type="text"
      className={classnames(styles.inputText, className)}
      {...props}
    />
  </div>
);
