import React from 'react';
import { ConnectedContainer } from '../../containers/connectedOrNotContainers';
import BasePage from '../../../base-page';
import FormUserAdd from './form';
import { useTranslation } from 'react-i18next';
import { useUserContext } from 'business/user/states';
import { UserRole } from 'common-active-invest-supervision/dist/src/business/user/types';
import styles from 'ui/styles/add-form.module.scss';
import { Navigate, useNavigate } from 'react-router';
import { Routes } from 'bootstrap/router';

const UserAdd: React.FC = () => {
  const { t } = useTranslation();
  const { user } = useUserContext();
  const navigate = useNavigate();

  if (
    user &&
    ![
      UserRole.GROUP_MANAGER,
      UserRole.COMPANY_MANAGER,
      UserRole.CLIENT_MANAGER,
    ].includes(user.role)
  ) {
    return <Navigate replace to={Routes.Home} />;
  }

  return (
    <ConnectedContainer>
      <BasePage className={styles.container}>
        <div>
          <button onClick={() => navigate(-1)} className={styles.returnButton}>
            &lt; {t('common.return')}
          </button>

          {user && <FormUserAdd />}
        </div>
      </BasePage>
    </ConnectedContainer>
  );
};

export default UserAdd;
