import { Routes } from 'bootstrap/router';
import { deleteDevice } from 'business/device/services/api';
import { UserContextParams } from 'business/user/states';
import { IDeviceDetailedV1Response } from 'common-active-invest-supervision/dist/src/business/device/api/v1';
import { UserRole } from 'common-active-invest-supervision/dist/src/business/user/types';
import queryString from 'query-string';
import { useTranslation } from 'react-i18next';
import { useNavigate, generatePath } from 'react-router';
import {
  redirectWithSuccess,
  redirectWithError,
} from 'technical/flash-message';
import { Button } from 'ui/button/button';
import { DeleteButton } from 'ui/button/delete-button';
import { ModifyButton } from 'ui/button/modify-button';

import styles from './index.module.scss';

const ModifyDeviceButton = ({ id }: { id: string }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  return (
    <ModifyButton
      className={styles.modifyButton}
      onClick={() => navigate(generatePath(Routes.DeviceModify, { id }))}
    >
      {t('device.view.modify')}
    </ModifyButton>
  );
};

const DeleteDeviceButton = ({ id }: { id: string }) => {
  const { t } = useTranslation();

  return (
    <DeleteButton
      className={styles.modifyButton}
      onClick={async () => {
        try {
          if (window.confirm(t('device.delete.confirm'))) {
            await deleteDevice(String(id));
            redirectWithSuccess('/devices', 'device.delete.success');
          }
        } catch (e) {
          redirectWithError('/devices', 'device.delete.error');
        }
      }}
    >
      {t('device.view.delete')}
    </DeleteButton>
  );
};

const ArtifactConfigurationButton = ({
  client,
  site,
  deviceType,
  deviceData,
  id,
}: {
  client: string | null;
  site: string | null;
  deviceType: string | null;
  deviceData: IDeviceDetailedV1Response;
  id: string;
}) => {
  const { t } = useTranslation();

  const navigate = useNavigate();

  return (
    <Button
      className={styles.modifyButton}
      colorMode="stroke"
      onClick={() => {
        const filters = queryString.stringify({
          client,
          site,
          deviceType,
        });
        navigate(
          generatePath(Routes.DeviceTypeConfigurationAdd, {
            id: deviceData.deviceType.id,
          }),
          {
            state: {
              filters,
              deviceId: id,
            },
          },
        );
      }}
    >
      {t('devicetype.view.newConfiguration')}
    </Button>
  );
};

export const Buttons = ({
  user,
  deviceData,
  id,
  client,
  site,
  deviceType,
}: {
  user: UserContextParams['user'];
  deviceData?: IDeviceDetailedV1Response;
  id: string;
  client: string | null;
  site: string | null;
  deviceType: string | null;
}) => {
  if (!user) {
    return <></>;
  }

  const isCompanyOrClientManager = [
    UserRole.COMPANY_MANAGER,
    UserRole.CLIENT_MANAGER,
  ].includes(user.role);

  const isCompanyManager = user.role === UserRole.COMPANY_MANAGER;

  const deviceHasConfiguration =
    deviceData &&
    deviceData.allowedArtifacts &&
    deviceData.allowedArtifacts.includes('configuration');

  return (
    <>
      {isCompanyOrClientManager ? <ModifyDeviceButton id={id} /> : null}

      {isCompanyManager ? (
        <>
          {deviceHasConfiguration ? (
            <ArtifactConfigurationButton
              client={client}
              site={site}
              deviceType={deviceType}
              deviceData={deviceData}
              id={id}
            />
          ) : null}

          <DeleteDeviceButton id={id} />
        </>
      ) : null}
    </>
  );
};
