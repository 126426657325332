import validate from 'validate.js';
import { UserAddValues } from '../form';
import {
  EMAIL,
  REQUIRED,
  EQUALITY,
  LOGIN,
  TRequired,
  TEmail,
  TEquality,
  TLogin,
} from '../../../../../technical/validation/rules';
import i18n from 'i18next';
import { UserRole } from 'common-active-invest-supervision/dist/src/business/user/types';

interface Constraints {
  firstName: TRequired;
  lastName: TRequired;
  email?: TEmail;
  phone?: TRequired;
  role: TRequired;
  companyId?: TRequired;
  clientId?: TRequired;
  login?: TLogin;
  password?: TRequired;
  confirmPassword?: TRequired & TEquality;
}

export const userAddValidation = (values: UserAddValues, modifyId?: string) => {
  const constraints: Constraints = {
    firstName: REQUIRED(i18n.t('user.add.form.firstName.required')),
    lastName: REQUIRED(i18n.t('user.add.form.lastName.required')),
    role: REQUIRED(i18n.t('user.add.form.role.required')),
  };

  // the user must fill only the email, or only the phone, or both email and phone
  if (!values.email && !values.phone) {
    constraints.email = EMAIL(i18n.t('user.add.form.email.required'));
    constraints.phone = REQUIRED(i18n.t('user.add.form.phone.required'));
  } else if (values.email) {
    constraints.email = EMAIL(i18n.t('user.add.form.email.required'));
  }

  // a companyId is expected is the selected role match a company role
  if (
    [UserRole.COMPANY_MANAGER, UserRole.COMPANY_OBSERVER].includes(
      values.role as UserRole,
    )
  ) {
    constraints.companyId = REQUIRED(
      i18n.t('user.add.form.companyId.required'),
    );
  }

  // a clientId is expected is the selected role match a client role
  if (
    [UserRole.CLIENT_MANAGER, UserRole.CLIENT_OBSERVER].includes(
      values.role as UserRole,
    )
  ) {
    constraints.clientId = REQUIRED(i18n.t('user.add.form.client.required'));
  }

  // the login, password and confirmPassword are required only when creating a user
  if (!modifyId) {
    constraints.login = LOGIN(i18n.t('user.add.form.login.invalid'));
    constraints.password = REQUIRED(i18n.t('user.add.form.password.required'));
    constraints.confirmPassword = {
      ...EQUALITY('password', i18n.t('user.add.form.confirmPassword.required')),
      ...REQUIRED(i18n.t('user.add.form.confirmPassword.required')),
    };
  }

  return validate(values, constraints);
};
