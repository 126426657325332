import React, { ReactNode } from 'react';
import { Page } from '../ui/layout/page';
import { useUserContext } from './user/states';
import { Header } from '../ui/layout/header';
import { useLocation, useNavigate } from 'react-router';
import { Routes } from 'bootstrap/router';

interface Props {
  children: ReactNode;
  footer?: ReactNode;
  className?: string;
}

const BasePage = ({ children, footer, className }: Props) => {
  const { isConnected, logout, user } = useUserContext();
  const location = useLocation();
  const navigate = useNavigate();

  const header =
    isConnected && user ? (
      <Header
        user={user}
        isConnected={isConnected}
        logout={logout}
        currentRoute={location.pathname}
        goToHome={() => {
          navigate(Routes.Home);
        }}
      />
    ) : (
      undefined
    );

  return (
    <Page header={header} contentClassName={className} footer={footer}>
      {children}
    </Page>
  );
};

export default BasePage;
