import { MarkerClusterer } from '@react-google-maps/api';
import { computeDeviceLocation } from 'business/device/services';
import { getMaxSeverity } from 'business/device/services/getMaxSeverity';
import { ALARM_STATUS } from 'common-active-invest-supervision/dist/src/business/alarm/types';
import { COMPANY_DEFAULT_CATEGORY_DEVICE } from 'common-active-invest-supervision/dist/src/business/company/types';
import {
  IDeviceDetailedV1Response,
  IDeviceLocationV1Response,
  IDeviceV1ResponseLight,
} from 'common-active-invest-supervision/dist/src/business/device/api/v1';
import React, { useCallback, useEffect, useState } from 'react';
import { FRANCE_REGION } from 'technical/google-maps/constants';
import { DeviceMarker } from 'ui/google-maps/markers/device';
import { ProjectMap } from 'ui/google-maps/project-map';
import { NoResults } from 'ui/no-results';
import { ListRow } from '../../../../../ui/list';
import styles from './index.module.scss';
import { useNavigate, generatePath } from 'react-router';
import { Routes } from 'bootstrap/router';
import {
  DeviceLocationWithLatLng,
  SiteLocation,
  isDeviceLocation,
} from 'business/device/types';

const DEFAULT_ZOOM_LEVEL = 7;

// This handles the case where a device is not associated to any
// deviceLocation and removes locations that have the same longitude or latitude
// to avoid clustering even when completely zoomed on map
const getDeviceLocations = (
  device: IDeviceV1ResponseLight,
  showPositionHistory: boolean,
): (DeviceLocationWithLatLng | SiteLocation)[] => {
  const mostRecentPosition = computeDeviceLocation(device);

  if (!showPositionHistory || !device.deviceLocation) {
    return [mostRecentPosition];
  }

  const mappedDeviceLocations = device.deviceLocation.map(location => {
    return {
      ...location,
      lat: location.position.x,
      lng: location.position.y,
    };
  });

  const filteredSamePositions: Array<Omit<
    IDeviceLocationV1Response,
    'position'
  > & {
    lat: number;
    lng: number;
  }> = [];

  mappedDeviceLocations.forEach(location => {
    if (
      !filteredSamePositions.find(
        position =>
          position.lat === location.lat && position.lng === location.lng,
      )
    ) {
      filteredSamePositions.push(location);
    }
  });

  return filteredSamePositions;
};

export const mapDeviceDetailedResponseToLightResponse = (
  deviceDetailedResponse: IDeviceDetailedV1Response,
): IDeviceV1ResponseLight => {
  return {
    ...deviceDetailedResponse,
    maintainer: deviceDetailedResponse.maintainer ?? {
      firstName: '',
      lastName: '',
    },
  };
};

// Only `BATTERY`is still relevant, others aren't used in business
const typeMapping = {
  [COMPANY_DEFAULT_CATEGORY_DEVICE.BATTERY]: 'battery',
  [COMPANY_DEFAULT_CATEGORY_DEVICE.ELECTRICAL_CABINET]: 'cabinet',
  [COMPANY_DEFAULT_CATEGORY_DEVICE.LIGHT]: 'lamp',
};

interface Props {
  devices: IDeviceV1ResponseLight[];
  filtersSearch: string;
  showDeviceList?: boolean;
  zoomLevel?: number;
  details?: boolean;
  showPositionHistory?: boolean;
}

function countAlerts(device: IDeviceV1ResponseLight) {
  return device.alarms.filter(
    alarm =>
      ![ALARM_STATUS.CLOSED, ALARM_STATUS.OPEN_NOT_NOTIFY].includes(
        alarm.status,
      ),
  ).length;
}

const DeviceList = ({
  devicesInBounds,
  filtersSearch,
}: {
  devicesInBounds: IDeviceV1ResponseLight[];
  filtersSearch: string;
}) => {
  const navigate = useNavigate();

  return (
    <div className={styles.listContainer}>
      <NoResults isVisible={!devicesInBounds.length} />

      {devicesInBounds.map(device => {
        const maxSeverity = getMaxSeverity(device);

        return (
          <ListRow
            key={device.id}
            listRowElements={[
              {
                text: device.description,
                type: typeMapping[device.category],
              },
            ]}
            onClick={() =>
              navigate(
                `${generatePath(Routes.DeviceView, {
                  id: device.id,
                })}?from-devices=true&${filtersSearch}`,
              )
            }
            severity={maxSeverity !== null ? maxSeverity : undefined}
          />
        );
      })}
    </div>
  );
};

function getLocationKey(
  device: IDeviceV1ResponseLight,
  location: DeviceLocationWithLatLng | SiteLocation,
) {
  return isDeviceLocation(location)
    ? `${device.id}-${location.date}`
    : `${device.id}`;
}

const LocationMarker = ({
  setCenter,
  infoWindowIndex,
  setInfoWindowIndex,
  location,
  device,
  clusterer,
  filtersSearch,
  details,
}: {
  setCenter: ({ lat, lng }: SiteLocation) => void;
  infoWindowIndex: string | null;
  setInfoWindowIndex: (arg0: string | null) => void;
  location: DeviceLocationWithLatLng | SiteLocation;
  device: IDeviceV1ResponseLight;
  filtersSearch: string;
  details: boolean;
  // The `Clusterer` type is not exported by @react-google-maps/api
  clusterer: any;
}) => {
  const navigate = useNavigate();

  const numberAlert = countAlerts(device);
  const maxSeverity = getMaxSeverity(device);

  const index = getLocationKey(device, location);

  const deviceData = {
    ...device,
    deviceLocation: location,
  };

  return (
    <DeviceMarker
      device={deviceData}
      hasInfoWindow={infoWindowIndex === index}
      onClick={() => {
        setCenter(location);
        setInfoWindowIndex(infoWindowIndex === index ? null : index);
      }}
      category={device.category}
      severity={maxSeverity ? maxSeverity : undefined}
      name={device.description}
      position={`${device.site.client.name}, ${device.site.name}`}
      numberAlert={numberAlert}
      seeMoreCallback={() =>
        navigate(
          `${generatePath(Routes.DeviceView, {
            id: device.id,
          })}?from-devices=true&${filtersSearch}`,
        )
      }
      maintainer={device.maintainer}
      clusterer={clusterer}
      details={details}
    />
  );
};

function preventTooMuchZoomOnFitBounds(
  mapInstance: google.maps.Map,
  maxZoomLevel: number,
) {
  google.maps.event.addListenerOnce(mapInstance, 'bounds_changed', function() {
    mapInstance.setZoom(
      Math.min(mapInstance.getZoom() ?? Infinity, maxZoomLevel),
    );
  });
}

function DevicesMap({
  devices,
  filtersSearch,
  showDeviceList = true,
  zoomLevel,
  details = true,
  showPositionHistory = false,
}: Props) {
  const [infoWindowIndex, setInfoWindowIndex] = useState<string | null>(null);
  const [center, setCenter] = useState<{
    lat: number;
    lng: number;
  }>(FRANCE_REGION.center);
  const [mapInstance, setMapInstance] = useState<google.maps.Map | null>(null);
  const [devicesInBounds, setDevicesInBounds] = useState<
    IDeviceV1ResponseLight[]
  >([]);

  const computeDevicesInBounds = useCallback(() => {
    if (mapInstance) {
      const bounds = mapInstance.getBounds();
      if (bounds) {
        setDevicesInBounds(
          devices.filter(device =>
            bounds.contains(computeDeviceLocation(device)),
          ),
        );
      }
    }
  }, [mapInstance, devices]);

  // This effect fit bounds each time new devices are received
  useEffect(() => {
    if (mapInstance && devices.length > 0) {
      const bounds = new google.maps.LatLngBounds();
      devices.map(computeDeviceLocation).forEach(point => bounds.extend(point));

      preventTooMuchZoomOnFitBounds(
        mapInstance,
        zoomLevel ?? DEFAULT_ZOOM_LEVEL,
      );

      mapInstance.fitBounds(bounds); // This will zoom as close to the markers on map as possible
    }
  }, [mapInstance, devices]);

  // This effect compute the devices in bounds each time devices are updated
  useEffect(() => {
    computeDevicesInBounds();
  }, [devices, computeDevicesInBounds]);

  const clusterMakerSvg = `
    <svg width="36" height="36" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx="18" cy="18" r="16" fill="white"/>
      <circle cx="18" cy="18" r="17" stroke="black" stroke-opacity="0.1" stroke-width="2"/>
    </svg>
  `;

  return (
    <div className={styles.mainContainer}>
      {showDeviceList ? (
        <DeviceList
          devicesInBounds={devicesInBounds}
          filtersSearch={filtersSearch}
        />
      ) : null}
      <ProjectMap
        center={center}
        zoom={zoomLevel ?? FRANCE_REGION.zoom}
        mapContainerClassName={styles.mapContainer}
        onClick={() => setInfoWindowIndex(null)}
        onLoad={setMapInstance}
        onIdle={computeDevicesInBounds}
      >
        <MarkerClusterer
          styles={[
            {
              url: `data:image/svg+xml;charset=UTF-8;base64,${btoa(
                clusterMakerSvg,
              )}`,
              width: 36,
              height: 36,
            },
          ]}
        >
          {clusterer => (
            <>
              {devices.map(device => {
                const locations = getDeviceLocations(
                  device,
                  showPositionHistory,
                );

                return locations.map(location => {
                  const key = getLocationKey(device, location);

                  return (
                    <LocationMarker
                      key={key}
                      setCenter={setCenter}
                      infoWindowIndex={infoWindowIndex}
                      setInfoWindowIndex={setInfoWindowIndex}
                      location={location}
                      device={device}
                      clusterer={clusterer}
                      filtersSearch={filtersSearch}
                      details={details}
                    />
                  );
                });
              })}
            </>
          )}
        </MarkerClusterer>
      </ProjectMap>
    </div>
  );
}

export default DevicesMap;
