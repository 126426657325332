import React, { useEffect } from 'react';
import NoMatch from 'business/user/pages/no-match';
import { Route } from 'react-router';

function ReportingNoMatch() {
  useEffect(() => {
    // Logs an event to Sentry
  });

  return <Route element={NoMatch} />;
}

export { ReportingNoMatch };
