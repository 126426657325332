import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import styles from '../login/index.module.scss';
import { NotConnectedContainer } from '../../containers/connectedOrNotContainers';
import { Spacer } from '../../../../ui/layout/spacer';
import { Field, Form, Formik } from 'formik';
import { InputText } from '../../../../ui/form/inputText';
import { Button } from 'ui/button/button';
import { forgotPasswordValidation } from './service';
import { requestChangePassword } from '../../services/api';
import BasePage from '../../../base-page';
import { Title } from '../../../../ui/typo/title';
import { LogosGroup } from 'ui/logos/logosGroup';

export interface ForgotPasswordValues {
  email: string;
}

const ForgotPasswordPage: React.FC = ({ children }) => (
  <NotConnectedContainer>
    <BasePage>
      <LogosGroup isHeader={false} />
      <Spacer>
        <Spacer pb={20} mw={400}>
          {children}
        </Spacer>
      </Spacer>
    </BasePage>
  </NotConnectedContainer>
);

const ForgotPassword = () => {
  const { t } = useTranslation();
  const [success, setSuccess] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);
  const [loading, setLoading] = useState<boolean>(false);

  if (success) {
    return (
      <ForgotPasswordPage>
        {t('user.forgot-password.form.success')}
      </ForgotPasswordPage>
    );
  }

  if (error !== null) {
    return (
      <ForgotPasswordPage>
        {t('user.forgot-password.form.error')}
      </ForgotPasswordPage>
    );
  }

  return (
    <ForgotPasswordPage>
      <Formik<ForgotPasswordValues>
        initialValues={{ email: '' }}
        onSubmit={async (values: ForgotPasswordValues) => {
          setLoading(true);
          try {
            await requestChangePassword(values);
          } catch (e) {
            setSuccess(false);
            setError(e instanceof Error ? e.message : 'generic-error');
          } finally {
            setLoading(false);
          }
          setSuccess(true);
          setError(null);
        }}
        validate={forgotPasswordValidation}
      >
        <Form className={styles.casualForm}>
          <Title
            className={styles.title}
            text={t('user.forgot-password.title')}
          />
          {t('user.forgot-password.description')}
          <Field
            className={styles.field}
            name="email"
            placeholder={t('user.forgot-password.form.email.placeholder')}
            component={InputText}
          />
          <div className={styles.actions}>
            <Button disabled={loading} type="submit">
              {t('user.forgot-password.form.submit')}
            </Button>
          </div>
        </Form>
      </Formik>
    </ForgotPasswordPage>
  );
};

export default ForgotPassword;
