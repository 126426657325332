import validate from 'validate.js';
import { DeviceAddValues } from '../form';
import {
  REQUIRED,
  NUMBER,
  TRequired,
  TNumber,
} from '../../../../../technical/validation/rules';
import i18n from 'i18next';

interface Constraints {
  deviceTypeId: TRequired;
  device: TRequired;
  description: TRequired;
  siteId: TRequired;
  latitude?: TRequired & TNumber;
  longitude?: TRequired & TNumber;
  type?: TRequired;
  dashboardId?: TRequired;
}

export const deviceAddValidation = (
  values: DeviceAddValues,
  modifyId?: string,
) => {
  const constraints: Constraints = {
    deviceTypeId: REQUIRED(i18n.t('device.add.form.deviceTypeId.required')),
    device: REQUIRED(i18n.t('device.add.form.device.error')),
    description: REQUIRED(i18n.t('device.add.form.description.required')),
    siteId: REQUIRED(i18n.t('device.add.form.siteId.required')),
  };

  if (values.latitude !== undefined || values.longitude !== undefined) {
    constraints.latitude = {
      ...REQUIRED(i18n.t('device.add.form.latitude.required')),
      ...NUMBER(i18n.t('device.add.form.latitude.error')),
    };
    constraints.longitude = {
      ...REQUIRED(i18n.t('device.add.form.longitude.required')),
      ...NUMBER(i18n.t('device.add.form.longitude.error')),
    };
  }

  if (modifyId) {
    constraints.dashboardId = REQUIRED(
      i18n.t('device.add.form.dashboardId.required'),
    );
  }

  return validate(values, constraints);
};
