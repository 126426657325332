import validate from 'validate.js';
import { DeviceTypeAddValues } from '..';
import {
  REQUIRED,
  NO_SPECIAL_CHARACTER,
} from '../../../../../technical/validation/rules';
import i18n from 'i18next';

export const devicetypeAddValidation = ({
  name,
  dashboardUrl,
  network,
}: DeviceTypeAddValues) => {
  const attributes = {
    name,
    dashboardUrl,
    network,
  };
  const constraints = {
    name: REQUIRED(i18n.t('devicetype.add.form.name.required')),
    dashboardUrl: NO_SPECIAL_CHARACTER(
      i18n.t('devicetype.add.form.dashboardUrl.error'),
    ),
    network: REQUIRED(i18n.t('devicetype.add.form.network.required')),
  };

  return validate(attributes, constraints);
};
