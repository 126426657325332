import { ANOMALY_OPERATOR } from 'common-active-invest-supervision/dist/src/business/alert/types';
import { IAlertV1Response } from 'common-active-invest-supervision/dist/src/business/alert/api/v1';
import { TFunction } from 'i18next';

const operatorFormat = {
  [ANOMALY_OPERATOR.EQUAL]: '=',
  [ANOMALY_OPERATOR.INFERIOROREQUAL]: '<=',
  [ANOMALY_OPERATOR.SUPERIOROREQUAL]: '>=',
  [ANOMALY_OPERATOR.INFERIOR]: '<',
  [ANOMALY_OPERATOR.SUPERIOR]: '>',
};

export function formatAnomaly(alert: IAlertV1Response, t: TFunction) {
  if (alert.anomaly.metric === 'inactive') {
    return t('alert.inactiveFormat', { count: alert.triggerPeriod });
  }

  return `${alert.anomaly.metric} ${operatorFormat[alert.anomaly.operator]} ${
    alert.anomaly.thresholdValue
  }`;
}

export function formatAnomalyView(alert: IAlertV1Response, t: TFunction) {
  if (alert.anomaly.metric === 'inactive') {
    return t('alert.view.mainInfo_inactive', {
      count: alert.triggerPeriod,
    });
  }

  return t('alert.view.mainInfo', {
    anomaly: formatAnomaly(alert, t),
    count: alert.triggerPeriod,
  });
}
