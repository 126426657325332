import Login from 'business/user/pages/login';
import * as React from 'react';
import {
  createBrowserRouter,
  RouterProvider,
  RouteObject,
} from 'react-router-dom';
import ForgotPassword from '../../business/user/pages/forgot-password';
import UserAdd from '../../business/user/pages/add';
import DeviceList from 'business/device/pages/list';
import DeviceView from 'business/device/pages/view';
import DeviceModify from 'business/device/pages/modify';
import DeviceTypeAdd from 'business/device-type/pages/add';
import CompanyAdd from 'business/company/pages/add';
import SiteAdd from 'business/site/pages/add';
import DeviceAdd from 'business/device/pages/add';
import ClientAdd from 'business/client/pages/add';
import UserList from 'business/user/pages/list';
import DeviceTypeList from 'business/device-type/pages/list';
import ClientList from 'business/client/pages/list';
import SiteList from 'business/site/pages/list';
import CompanyList from 'business/company/pages/list';
import CompanyView from 'business/company/pages/view';
import SiteView from 'business/site/pages/view';
import UserView from 'business/user/pages/view';
import DeviceTypeView from 'business/device-type/pages/view';
import AlertAdd from 'business/alert/pages/add';
import ClientView from 'business/client/pages/view';
import AlertList from 'business/alert/pages/list';
import SiteModify from 'business/site/pages/modify';
import AlarmList from 'business/alarm/pages/list';
import CompanyModify from 'business/company/pages/modify';
import UserModify from 'business/user/pages/modify';
import AlertView from 'business/alert/pages/view';
import AlertModify from 'business/alert/pages/modify';
import ClientModify from 'business/client/pages/modify';
import DeviceTypeModify from 'business/device-type/pages/modify';
import ArtifactFirmwareAdd from 'business/artifact/firmware/pages/add';
import ArtifactConfigurationAdd from 'business/artifact/configuration/pages/add';
import { Home } from 'business/user/pages/home';
import { ReportingNoMatch } from 'technical/router/switch/reporting-no-match';

export enum Routes {
  'Home' = '/',
  'HomeNotConnected' = '/login',
  'HomeConnected' = '/devices',

  // Not-connected routes
  // eslint-disable-next-line @typescript-eslint/no-duplicate-enum-values
  'Login' = '/login',
  'ForgotPassword' = '/forgot-password',

  // Users
  'UserList' = '/users',
  'UserAdd' = '/user/create',
  'UserView' = '/user/view/:id',
  'UserModify' = '/user/modify/:id',

  // Device Type
  'DeviceTypeList' = '/device-types',
  'DeviceTypeAdd' = '/device-type/add',
  'DeviceTypeView' = '/device-type/view/:id',
  'DeviceTypeModify' = '/device-type/modify/:id',

  // Device Type Artifact
  'DeviceTypeFirmwareAdd' = '/device-type/:id/firmware/add',
  'DeviceTypeConfigurationAdd' = '/device-type/:id/configuration/add',

  // Company
  'CompanyList' = '/companies',
  'CompanyAdd' = '/company/create',
  'CompanyView' = '/company/view/:id',
  'CompanyModify' = '/company/modify/:id',

  // Site
  'SiteList' = '/sites',
  'SiteAdd' = '/site/add',
  'SiteView' = '/site/view/:id',
  'SiteModify' = '/site/modify/:id',

  // Device
  // eslint-disable-next-line @typescript-eslint/no-duplicate-enum-values
  'DeviceList' = '/devices',
  'DeviceAdd' = '/device/add',
  'DeviceView' = '/device/view/:id',
  'DeviceModify' = '/device/modify/:id',

  // Client
  'ClientList' = '/clients',
  'ClientAdd' = '/client/add',
  'ClientView' = '/client/view/:id',
  'ClientModify' = '/client/update/:id',

  // Alert
  'AlertList' = '/alerts',
  'AlertAdd' = '/alert/add',
  'AlertView' = '/alert/view/:id',
  'AlertModify' = '/alert/modify/:id',

  // Alarm
  'AlarmList' = '/alarms',
}

const routeProps: RouteObject[] = [
  {
    errorElement: <ReportingNoMatch />,
    children: [
      {
        path: Routes.Home,
        element: <Home />,
        errorElement: <ReportingNoMatch />,
      },
      {
        path: Routes.Login,
        element: <Login />,
      },
      {
        path: Routes.ForgotPassword,
        element: <ForgotPassword />,
      },
      {
        path: Routes.UserAdd,
        element: <UserAdd />,
      },
      {
        path: Routes.UserList,
        element: <UserList />,
      },
      {
        path: Routes.UserView,
        element: <UserView />,
      },
      {
        path: Routes.UserModify,
        element: <UserModify />,
      },
      {
        path: Routes.DeviceList,
        element: <DeviceList />,
      },
      {
        path: Routes.DeviceAdd,
        element: <DeviceAdd />,
      },
      {
        path: Routes.DeviceView,
        element: <DeviceView />,
      },
      {
        path: Routes.DeviceModify,
        element: <DeviceModify />,
      },
      {
        path: Routes.CompanyList,
        element: <CompanyList />,
      },
      {
        path: Routes.CompanyAdd,
        element: <CompanyAdd />,
      },
      {
        path: Routes.CompanyView,
        element: <CompanyView />,
      },
      {
        path: Routes.CompanyModify,
        element: <CompanyModify />,
      },
      {
        path: Routes.DeviceTypeList,
        element: <DeviceTypeList />,
      },
      {
        path: Routes.DeviceTypeAdd,
        element: <DeviceTypeAdd />,
      },
      {
        path: Routes.DeviceTypeView,
        element: <DeviceTypeView />,
      },
      {
        path: Routes.DeviceTypeModify,
        element: <DeviceTypeModify />,
      },
      {
        path: Routes.SiteList,
        element: <SiteList />,
      },
      {
        path: Routes.SiteAdd,
        element: <SiteAdd />,
      },
      {
        path: Routes.SiteView,
        element: <SiteView />,
      },
      {
        path: Routes.SiteModify,
        element: <SiteModify />,
      },
      {
        path: Routes.ClientList,
        element: <ClientList />,
      },
      {
        path: Routes.ClientAdd,
        element: <ClientAdd />,
      },
      {
        path: Routes.ClientView,
        element: <ClientView />,
      },
      {
        path: Routes.ClientModify,
        element: <ClientModify />,
      },
      {
        path: Routes.AlertList,
        element: <AlertList />,
      },
      {
        path: Routes.AlertAdd,
        element: <AlertAdd />,
      },
      {
        path: Routes.AlertView,
        element: <AlertView />,
      },
      {
        path: Routes.AlertModify,
        element: <AlertModify />,
      },
      {
        path: Routes.AlarmList,
        element: <AlarmList />,
      },
      {
        path: Routes.DeviceTypeFirmwareAdd,
        element: <ArtifactFirmwareAdd />,
      },
      {
        path: Routes.DeviceTypeConfigurationAdd,
        element: <ArtifactConfigurationAdd />,
      },
      {
        path: '*',
        element: <ReportingNoMatch />,
      },
    ],
  },
];

const router = createBrowserRouter(routeProps);

export const Router = () => {
  return <RouterProvider router={router} />;
};
