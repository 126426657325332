import React, { useEffect, useState } from 'react';
import BasePage from '../../../base-page';
import { useTranslation } from 'react-i18next';
import { UserRole } from 'common-active-invest-supervision/dist/src/business/user/types';
import styles from '../../../../ui/styles/add-form.module.scss';
import { ConnectedContainer } from 'business/user/containers/connectedOrNotContainers';
import { useUserContext } from 'business/user/states';
import { Navigate, useNavigate, useParams } from 'react-router';
import { getDevice } from 'business/device/services/api';
import { IDeviceDetailedV1Response } from 'common-active-invest-supervision/dist/src/business/device/api/v1';
import FormDeviceAdd from '../add/form';
import { Loader } from 'ui/loader';
import { computeDeviceLocation } from 'business/device/services';
import { Routes } from 'bootstrap/router';

export default function DeviceModify() {
  const { t } = useTranslation();
  const { user } = useUserContext();
  const navigate = useNavigate();
  const { id } = useParams<{ id: string }>();
  const [device, setDevice] = useState<IDeviceDetailedV1Response>();

  useEffect(() => {
    const fetchData = async () => {
      if (id) {
        try {
          const result = await getDevice(id);
          setDevice(result);
        } catch (err) {
          console.error(err);
        }
      }
    };

    fetchData();
  }, [id]);

  if (
    user &&
    ![UserRole.COMPANY_MANAGER, UserRole.CLIENT_MANAGER].includes(user.role)
  ) {
    return <Navigate replace to={Routes.Home} />;
  }

  return (
    <ConnectedContainer>
      <BasePage className={styles.container}>
        <div>
          <button onClick={() => navigate(-1)} className={styles.returnButton}>
            &lt; {t('common.return')}
          </button>

          {device ? (
            <FormDeviceAdd
              initialData={{
                deviceTypeId: device.deviceType.id,
                maintainerId: device.maintainer ? device.maintainer.id : null,
                device: device.device,
                description: device.description,
                siteId: device.site.id,
                latitude: computeDeviceLocation(device).lat,
                longitude: computeDeviceLocation(device).lng,
                altitude: device.deviceLocation?.at(0)?.altitude ?? 0,
                precision: device.deviceLocation?.at(0)?.precision ?? 0,
                dashboardId: device.dashboardId,
              }}
              modifyId={id}
            />
          ) : (
            <Loader />
          )}
        </div>
      </BasePage>
    </ConnectedContainer>
  );
}
