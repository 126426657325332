import { DeviceLocationType } from 'common-active-invest-supervision/dist/src/business/device/types';
import { axios } from '../../../technical/network';
import {
  IDeviceDetailedV1Response,
  IDeviceV1Response,
  IDeviceV1ResponseLight,
} from 'common-active-invest-supervision/dist/src/business/device/api/v1';

export async function getDevice(
  id: string,
): Promise<IDeviceDetailedV1Response> {
  const response = await axios.get(`/device/${id}`);

  return response.data;
}

export async function fetchDevices(): Promise<
  | {
      items: IDeviceDetailedV1Response[];
    }
  | undefined
> {
  const response = await axios.get('devices');
  if (response.status === 200) {
    return response.data;
  }
}

export async function fetchDevicesLight(): Promise<
  | {
      items: IDeviceV1ResponseLight[];
    }
  | undefined
> {
  const response = await axios.get('devices/light');
  if (response.status === 200) {
    return response.data;
  }
}

export const DEVICE_POST_REQUEST_FAILED = 'DEVICE_POST_REQUEST_FAILED';
export type DevicePostType = {
  deviceTypeId: string;
  maintainerId: string | null;
  device: string;
  description: string;
  siteId: string;
  dashboardId?: string;
  location?: {
    position: {
      x?: number;
      y?: number;
    };
    altitude?: number;
    precision?: number;
    type?: DeviceLocationType;
  };
};
export async function post(values: DevicePostType): Promise<void> {
  const response = await axios.post<IDeviceV1Response>('/device', values);
  if (response.status !== 201) {
    throw new Error(DEVICE_POST_REQUEST_FAILED);
  }
}

const DEVICE_DELETE_REQUEST_FAILED = 'DEVICE_DELETE_REQUEST_FAILED';
export async function deleteDevice(id: string): Promise<void> {
  const response = await axios.delete<void>(`/device/${id}`);
  if (response.status !== 204) {
    throw new Error(DEVICE_DELETE_REQUEST_FAILED);
  }
}

export async function updateDevice(
  id: string,
  values: DevicePostType,
): Promise<void> {
  const response = await axios.put(`/device/${id}`, values);
  if (response.status !== 200) {
    throw new Error();
  }
}
