import { axios } from '../../../technical/network';
import { IAlarmDetailedV1Response } from 'common-active-invest-supervision/dist/src/business/alarm/api/v1';
import queryString from 'query-string';

export async function listAlarms(params: {
  page?: number;
  limit?: number;
  clientId?: string;
  siteId?: string;
  deviceTypeId?: string;
  status?: string;
}): Promise<{
  items: IAlarmDetailedV1Response[];
  metadata: {
    currentPage: number;
    pageCount: number;
  };
}> {
  const filter = {
    page: params.page ? params.page : 1,
    limit: params.limit ? params.limit : undefined,
    clientId: params.clientId ? params.clientId : undefined,
    siteId: params.siteId ? params.siteId : undefined,
    deviceTypeId: params.deviceTypeId ? params.deviceTypeId : undefined,
    status: params.status ? params.status : undefined,
  };
  const stringifiedQuery = queryString.stringify(filter);

  const response = await axios.get(
    `/alarms${stringifiedQuery ? `?${stringifiedQuery}` : ''}`,
  );

  return response.data;
}

const ALARM_ACKNOLEDGED_POST_REQUEST_FAILED =
  'ALARM_ACKNOLEDGED_POST_REQUEST_FAILED';
export async function acknowledgeAlarm(id: string) {
  const response = await axios.post(`/alarm/${id}/acknowledge`);
  if (response.status !== 200) {
    throw new Error(ALARM_ACKNOLEDGED_POST_REQUEST_FAILED);
  }
}

export async function exportCsv(params: {
  clientId?: string;
  siteId?: string;
  deviceTypeId?: string;
  status?: string;
}) {
  const response = await axios.get('/export-alarms', {
    params,
    responseType: 'blob',
  });

  if (response.status !== 200) {
    throw new Error();
  }

  // Download file with native browser behaviour
  const url = window.URL.createObjectURL(new Blob([response.data]));
  const link = document.createElement('a');
  link.href = url;
  link.setAttribute('download', 'export-alarmes.csv');
  link.click();
  window.URL.revokeObjectURL(url);
}
