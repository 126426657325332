import React, { useEffect, useState } from 'react';
import BasePage from '../../../base-page';
import { useTranslation } from 'react-i18next';
import { ConnectedContainer } from 'business/user/containers/connectedOrNotContainers';
import styles from 'ui/styles/view.module.scss';
import { SectionTitle } from 'ui/typo/section-title';

import { Navigate, useNavigate, useParams, generatePath } from 'react-router';
import { IDeviceTypeV1Response } from 'common-active-invest-supervision/dist/src/business/deviceType/api/v1';
import {
  getDeviceType,
  deleteDeviceType,
} from 'business/device-type/services/api';
import { Icon } from 'ui/icons';
import { formatDateLLL } from 'technical/date';
import { useUserContext } from 'business/user/states';
import { UserRole } from 'common-active-invest-supervision/dist/src/business/user/types';
import { Loader } from 'ui/loader';
import {
  redirectWithSuccess,
  redirectWithError,
} from 'technical/flash-message';
import { DeleteButton } from 'ui/button/delete-button';
import { ModifyButton } from 'ui/button/modify-button';
import { Button } from 'ui/button/button';
import { Link } from 'react-router-dom';
import { Routes } from 'bootstrap/router';
import { invariant } from 'technical/router/query-params';
import { InfoMessage } from 'ui/info-message';

const DeviceTypeView: React.FC = () => {
  const { t } = useTranslation();
  const { id } = useParams<{ id: string }>();
  invariant(id);
  const [deviceType, setDeviceType] = useState<IDeviceTypeV1Response>();

  const navigate = useNavigate();
  const { user } = useUserContext();

  useEffect(() => {
    if (id) {
      getDeviceType(id)
        .then(data => setDeviceType(data))
        .catch(error => console.error(error));
    }
  }, [id]);

  const authorizedRoles = [
    UserRole.COMPANY_MANAGER,
    UserRole.COMPANY_OBSERVER,
    UserRole.GROUP_MANAGER,
    UserRole.GROUP_OBSERVER,
  ];
  if (user && !authorizedRoles.includes(user.role)) {
    return <Navigate replace to={Routes.Home} />;
  }

  const authorizedToManageRoles = [UserRole.COMPANY_MANAGER];
  const isAuthorizedToManage =
    user && authorizedToManageRoles.includes(user.role);
  const buttons = isAuthorizedToManage ? (
    <>
      <ModifyButton
        className={styles.modifyButton}
        onClick={() => navigate(generatePath(Routes.DeviceTypeModify, { id }))}
      >
        {t('common.modify')}
      </ModifyButton>
      <DeleteButton
        className={styles.modifyButton}
        onClick={async () => {
          try {
            if (window.confirm(t('devicetype.delete.confirm'))) {
              await deleteDeviceType(String(id));
              redirectWithSuccess('/device-types', 'devicetype.delete.success');
            }
          } catch (e) {
            redirectWithError('/device-types', 'devicetype.delete.error');
          }
        }}
      >
        {t('common.delete')}
      </DeleteButton>
    </>
  ) : null;

  return (
    <ConnectedContainer>
      <BasePage className={styles.container} footer={buttons}>
        <div>
          <button onClick={() => navigate(-1)} className={styles.returnButton}>
            &lt; {t('common.return')}
          </button>

          <InfoMessage />

          {deviceType ? (
            <>
              <SectionTitle text={deviceType.name} />
              <div className={styles.flex}>
                <div className={styles.spacer} />
                <div className={styles.mainButton}>{buttons}</div>
              </div>

              <div className={styles.panelContainer}>
                <div>
                  <div
                    className={styles.rowIconText}
                    title={t('devicetype.view.network')}
                  >
                    <Icon width={16} height={16}>
                      help
                    </Icon>
                    <div className={styles.details}>{deviceType.network}</div>
                  </div>

                  <div
                    className={styles.rowIconText}
                    title={t('devicetype.view.grafana')}
                  >
                    <Icon width={16} height={16}>
                      graph
                    </Icon>
                    <div className={styles.details}>
                      {deviceType.dashboardUrl}
                    </div>
                  </div>

                  <div className={styles.rowIconText}>
                    <Icon width={16} height={16}>
                      clock
                    </Icon>
                    <div className={styles.details}>
                      {`${t('common.created')} ${formatDateLLL(
                        deviceType.creationTime,
                      )} \n${t('common.updated')} ${formatDateLLL(
                        deviceType.updateTime,
                      )}`}
                    </div>
                  </div>

                  {deviceType.artifactTypeVersions &&
                    deviceType.artifactTypeVersions.firmware && (
                      <div className={styles.rowIconText}>
                        <Icon width={16} height={16}>
                          graph
                        </Icon>
                        <div className={styles.details}>
                          {t('devicetype.view.firmware', {
                            version:
                              deviceType.artifactTypeVersions.firmware
                                .globalTargetVersion,
                          })}
                        </div>
                      </div>
                    )}

                  {isAuthorizedToManage && deviceType.allowedArtifacts && (
                    <div>
                      {deviceType.allowedArtifacts.includes('firmware') && (
                        <Link
                          to={`/device-type/${id}/firmware/add`}
                          className={styles.link}
                        >
                          <Button
                            className={styles.modifyButton}
                            colorMode="stroke"
                          >
                            {t('devicetype.view.newFirmware')}
                          </Button>
                        </Link>
                      )}
                      {deviceType.allowedArtifacts.includes(
                        'configuration',
                      ) && (
                        <Link
                          to={`/device-type/${id}/configuration/add`}
                          className={styles.link}
                        >
                          <Button
                            className={styles.modifyButton}
                            colorMode="stroke"
                          >
                            {t('devicetype.view.newConfiguration')}
                          </Button>
                        </Link>
                      )}
                    </div>
                  )}
                </div>
              </div>
            </>
          ) : (
            <Loader />
          )}
        </div>
      </BasePage>
    </ConnectedContainer>
  );
};

export default DeviceTypeView;
