import { AxiosInstance, InternalAxiosRequestConfig } from 'axios';
import { getSession } from '../../../business/user/services/sessionStore';

function handleAuth(config: InternalAxiosRequestConfig) {
  const bearer = getSession();
  if (bearer) {
    config.headers.setAuthorization(`Bearer ${bearer}`);
  }

  return config;
}

const requestHandler: [
  (
    value: InternalAxiosRequestConfig,
  ) => InternalAxiosRequestConfig | Promise<InternalAxiosRequestConfig>,
  (error: any) => any,
] = [config => handleAuth(config), error => Promise.reject(error)];

function addAuthInterceptors(instance: AxiosInstance) {
  const interceptor = instance.interceptors.request.use(...requestHandler);

  return () => instance.interceptors.request.eject(interceptor);
}

export default addAuthInterceptors;
