import { axios } from '../../../technical/network';
import {
  IAlertV1CreateInput,
  IAlertV1UpdateInput,
  IAlertV1Response,
  IAlertDetailedV1Response,
} from 'common-active-invest-supervision/dist/src/business/alert/api/v1';
import queryString from 'query-string';

export async function getAlert(id: string): Promise<IAlertDetailedV1Response> {
  const response = await axios.get(`/alert/${id}`);

  return response.data;
}

export async function listAlerts(params: {
  page?: number;
  limit?: number;
}): Promise<{
  items: IAlertDetailedV1Response[];
  metadata: {
    currentPage: number;
    pageCount: number;
  };
}> {
  const filter = {
    page: params.page ? params.page : 1,
    limit: params.limit ? params.limit : undefined,
  };
  const stringifiedQuery = queryString.stringify(filter);

  const response = await axios.get(
    `/alerts${stringifiedQuery ? `?${stringifiedQuery}` : ''}`,
  );

  return response.data;
}

const ALERT_POST_REQUEST_FAILED = 'ALERT_POST_REQUEST_FAILED';

export async function post(params: IAlertV1CreateInput): Promise<void> {
  const response = await axios.post<IAlertV1Response>('/alert', params);
  if (response.status !== 201) {
    throw new Error(ALERT_POST_REQUEST_FAILED);
  }
}

const ALERT_PUT_REQUEST_FAILED = 'ALERT_PUT_REQUEST_FAILED';
export async function updateAlert(
  id: string,
  params: IAlertV1UpdateInput,
): Promise<void> {
  const response = await axios.put<IAlertV1Response>(`/alert/${id}`, params);
  if (response.status !== 200) {
    throw new Error(ALERT_PUT_REQUEST_FAILED);
  }
}

const ALERT_DELETE_REQUEST_FAILED = 'ALERT_DELETE_REQUEST_FAILED';
export async function deleteAlert(id: string): Promise<void> {
  const response = await axios.delete<void>(`/alert/${id}`);
  if (response.status !== 204) {
    throw new Error(ALERT_DELETE_REQUEST_FAILED);
  }
}
