import { acknowledgeAlarm } from 'business/alarm/services/api';
import { ConnectedContainer } from 'business/user/containers/connectedOrNotContainers';
import { UserContextParams, useUserContext } from 'business/user/states';
import classNames from 'classnames';
import { ALARM_STATUS } from 'common-active-invest-supervision/dist/src/business/alarm/types';
import { IDeviceDetailedV1Response } from 'common-active-invest-supervision/dist/src/business/device/api/v1';
import { UserRole } from 'common-active-invest-supervision/dist/src/business/user/types';
import { factoryDateTime } from 'common-active-invest-supervision/dist/src/technical/service/datetime';
import fr from 'date-fns/locale/fr';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import DatePicker, { registerLocale } from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router';
import { formatDateL, formatDateLT } from 'technical/date';

import { Button } from 'ui/button/button';
import datePickerStyles from 'ui/form/date-picker/index.module.scss';
import { Icon } from 'ui/icons';
import { Loader } from 'ui/loader';
import BasePage from '../../../base-page';
import { getDevice } from '../../services/api';
import styles from './index.module.scss';
import { invariant } from 'technical/router/query-params';
import { BackToDeviceListButton } from 'business/device/pages/view/back-to-device-list-button';
import { InfoMessage } from 'ui/info-message';
import { SectionTitle } from 'ui/typo/section-title';
import { Buttons } from 'business/device/pages/view/buttons';
import DevicesMap, {
  mapDeviceDetailedResponseToLightResponse,
} from 'business/device/pages/list/devices-map';

const DeviceDetailHeader = ({
  deviceData,
  buttons,
}: {
  deviceData: IDeviceDetailedV1Response;
  buttons: JSX.Element;
}) => {
  return (
    <div className={styles.headerContainer}>
      <SectionTitle
        text={`${deviceData.description} (${deviceData.deviceType.name})`}
      />
      <div className={styles.mainButton}>{buttons}</div>
    </div>
  );
};

const DeviceAlarms = ({
  deviceAlarms,
  user,
  fetchData,
}: {
  deviceAlarms: any[];
  user: UserContextParams['user'];
  fetchData: () => void;
}) => {
  const { t } = useTranslation();

  return (
    <>
      {deviceAlarms
        .filter(alarm =>
          [
            ALARM_STATUS.OPEN_NOT_ACKNOWLEDGED,
            ALARM_STATUS.OPEN_ACKNOWLEDGED,
          ].includes(alarm.status),
        )
        .map(alarm => (
          <div
            key={alarm.id}
            className={classNames(
              styles.alertDialog,
              ALARM_STATUS.OPEN_ACKNOWLEDGED === alarm.status
                ? styles.alertAcknowlegedDialog
                : null,
            )}
          >
            <span className={styles.alertText}>
              {t('device.view.opened', {
                alertName: alarm.alert.name,
                date: formatDateL(alarm.creationTime),
                time: formatDateLT(alarm.creationTime),
              })}
            </span>

            {ALARM_STATUS.OPEN_ACKNOWLEDGED === alarm.status &&
            alarm.acknowledgeBy &&
            alarm.acknowledgeTime ? (
              <span className={styles.alertText}>
                {t('device.view.acknowledged', {
                  firstName: alarm.acknowledgeBy.firstName,
                  lastName: alarm.acknowledgeBy.lastName,
                  date: formatDateL(alarm.acknowledgeTime),
                  time: formatDateLT(alarm.acknowledgeTime),
                })}
              </span>
            ) : null}

            {ALARM_STATUS.OPEN_NOT_ACKNOWLEDGED === alarm.status &&
              user &&
              [
                UserRole.COMPANY_MANAGER,
                UserRole.CLIENT_MANAGER,
                UserRole.MAINTAINER,
              ].includes(user.role) && (
                <Button
                  colorRole="danger"
                  colorMode="stroke"
                  className={styles.alertButton}
                  onClick={async () => {
                    try {
                      await acknowledgeAlarm(alarm.id);
                      fetchData();
                    } catch (err) {
                      console.error(err);
                    }
                  }}
                >
                  {t('device.view.free')}
                </Button>
              )}
          </div>
        ))}
    </>
  );
};

const DeviceLocationInfos = ({
  deviceData,
}: {
  deviceData: IDeviceDetailedV1Response;
}) => {
  return (
    <div className={styles.rowIconText}>
      <Icon width={16} height={16}>
        location
      </Icon>
      <div id="siteName" className={styles.details}>
        <div>{deviceData.site.name}</div>
        <div>{deviceData.site.client.name}</div>
        <div>
          {deviceData.deviceLocation?.length
            ? deviceData.deviceLocation[0].position.x
            : deviceData.site.location.x}
          ,{' '}
          {deviceData.deviceLocation?.length
            ? deviceData.deviceLocation[0].position.y
            : deviceData.site.location.y}
        </div>
      </div>
    </div>
  );
};

const DeviceArtifactsInfos = ({
  deviceData,
}: {
  deviceData: IDeviceDetailedV1Response;
}) => {
  const { t } = useTranslation();

  return (
    <>
      {deviceData.artifacts &&
        deviceData.allowedArtifacts &&
        deviceData.allowedArtifacts.length > 0 && (
          <>
            {/* Firmware */}
            <div className={styles.rowIconText}>
              <div className={styles.details}>
                <div className={styles.title}>{t('device.view.firmware')}</div>
                <div className={styles.artifact}>
                  {deviceData.artifacts.firmware &&
                  deviceData.artifacts.firmware.current
                    ? deviceData.artifacts.firmware.current.version
                    : t('device.view.unAvailable')}
                </div>
                <div className={styles.artifact}>
                  {t('device.view.target', {
                    version:
                      deviceData.artifacts.firmware &&
                      deviceData.artifacts.firmware.target
                        ? deviceData.artifacts.firmware.target.version
                        : t('device.view.unAvailable'),
                  })}
                </div>
              </div>
            </div>
            {/* Configuration */}
            <div className={styles.rowIconText}>
              <div className={styles.details}>
                <div className={styles.title}>
                  {t('device.view.configuration')}
                </div>
                <div className={styles.artifact}>
                  {deviceData.artifacts.configuration &&
                  deviceData.artifacts.configuration.current
                    ? deviceData.artifacts.configuration.current.version
                    : t('device.view.unAvailable')}
                </div>
                <div className={styles.artifact}>
                  {t('device.view.target', {
                    version:
                      deviceData.artifacts.configuration &&
                      deviceData.artifacts.configuration.target
                        ? deviceData.artifacts.configuration.target.version
                        : t('device.view.unAvailable'),
                  })}
                </div>
              </div>
            </div>
          </>
        )}
    </>
  );
};

const DeviceMaintainerInfos = ({
  deviceData,
}: {
  deviceData: IDeviceDetailedV1Response;
}) => {
  const { t } = useTranslation();

  return (
    <div>
      {deviceData.maintainer && (
        <div className={styles.rowIconText} title={t('common.maintainer')}>
          <Icon width={16} height={16}>
            settings
          </Icon>
          <div id="maintainerName" className={styles.details}>
            <div className={styles.title}>{t('common.maintainer')}</div>
            {`${deviceData.maintainer.firstName} ${deviceData.maintainer.lastName}`}
            {deviceData.maintainer.phone
              ? `\n${deviceData.maintainer.phone}`
              : ''}
            {deviceData.maintainer.email
              ? `\n${deviceData.maintainer.email}`
              : ''}
          </div>
        </div>
      )}
    </div>
  );
};

const DeviceGrafanaDashboard = ({
  deviceData,
}: {
  deviceData: IDeviceDetailedV1Response;
}) => {
  const { t, i18n } = useTranslation();

  const dateTime = factoryDateTime();
  const [endDate, setEndDate] = useState(dateTime.getNow());
  const [startDate, setStartDate] = useState(dateTime.subXDaysAgo(7));

  // Periodically reload the Grafana iframe
  const iframeRef = useRef<HTMLIFrameElement>(null);
  useEffect(() => {
    const delay = 15 * 60 * 1000;
    const timer = setInterval(() => {
      if (!iframeRef.current) {
        return;
      }

      iframeRef.current.src = iframeRef.current.src;
    }, delay);

    return () => clearInterval(timer);
  });

  return (
    <div>
      <div className={styles.grafanaTimeSelection}>
        <span>{t('device.view.from')}</span>
        <DatePicker
          className={styles.inputText}
          selected={startDate}
          dayClassName={date =>
            startDate && date.getDate() === startDate.getDate()
              ? datePickerStyles.selectedDay
              : ''
          }
          onChange={(date: Date) => setStartDate(date)}
          locale={i18n.language}
          showTimeSelect
          dateFormat="Pp"
        />
        <span>{t('device.view.to')}</span>
        <DatePicker
          className={styles.inputText}
          selected={endDate}
          dayClassName={date =>
            endDate && date.getDate() === endDate.getDate()
              ? datePickerStyles.selectedDay
              : ''
          }
          onChange={(date: Date) => setEndDate(date)}
          locale={i18n.language}
          showTimeSelect
          dateFormat="Pp"
        />
      </div>
      {deviceData.dashboardUrl && (
        <iframe
          ref={iframeRef}
          title={`graphana-dashboard`}
          className={styles.grafanaFrame}
          src={`${
            deviceData.dashboardUrl
          }&from=${startDate.getTime()}&to=${endDate.getTime()}`}
        />
      )}
    </div>
  );
};

const DeviceData = ({
  deviceData,
  user,
  fetchData,
  buttons,
}: {
  deviceData: IDeviceDetailedV1Response;
  user: UserContextParams['user'];
  fetchData: () => Promise<void>;
  buttons: JSX.Element;
}) => {
  return (
    <>
      <DeviceDetailHeader deviceData={deviceData} buttons={buttons} />
      <DeviceAlarms
        deviceAlarms={deviceData.alarms}
        user={user}
        fetchData={fetchData}
      />
      <div className={styles.panelAndMap}>
        <div className={styles.informationPanel}>
          <DeviceLocationInfos deviceData={deviceData} />
          <DeviceArtifactsInfos deviceData={deviceData} />
          <DeviceMaintainerInfos deviceData={deviceData} />
        </div>
        <div className={styles.map}>
          <DevicesMap
            devices={[mapDeviceDetailedResponseToLightResponse(deviceData)]}
            filtersSearch={''}
            showDeviceList={false}
            zoomLevel={10}
            details={false}
            showPositionHistory={true}
          />
        </div>
      </div>
      <DeviceGrafanaDashboard deviceData={deviceData} />
    </>
  );
};

const DeviceView: React.FC = () => {
  const { id } = useParams<{ id: string }>();
  invariant(id);
  const [deviceData, setDeviceData] = useState<IDeviceDetailedV1Response>();
  const { user } = useUserContext();
  const [comeFromListDevice, setComeFromListDevice] = useState<string | null>(
    null,
  );
  const [client, setClient] = useState<string | null>(null);
  const [site, setSite] = useState<string | null>(null);
  const [deviceType, setDeviceType] = useState<string | null>(null);

  const fetchData = useCallback(async () => {
    if (id) {
      const result = await getDevice(id);
      setDeviceData(result);
    }
  }, [id]);

  useEffect(() => {
    registerLocale('fr-FR', fr);
    fetchData();
  }, [fetchData]);

  // Get URL params
  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    setComeFromListDevice(urlParams.get('from-devices'));
    setClient(urlParams.get('client'));
    setSite(urlParams.get('site'));
    setDeviceType(urlParams.get('deviceType'));
  }, []);

  const buttons = (
    <Buttons
      user={user}
      deviceData={deviceData}
      id={id}
      client={client}
      site={site}
      deviceType={deviceType}
    />
  );

  return (
    <ConnectedContainer>
      <BasePage className={styles.container} footer={buttons}>
        <div>
          <BackToDeviceListButton
            client={client}
            site={site}
            deviceType={deviceType}
            comeFromListDevice={comeFromListDevice}
          />
          <InfoMessage />

          {deviceData ? (
            <DeviceData
              deviceData={deviceData}
              user={user}
              fetchData={fetchData}
              buttons={buttons}
            />
          ) : (
            <Loader />
          )}
        </div>
      </BasePage>
    </ConnectedContainer>
  );
};

export default DeviceView;
