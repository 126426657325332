import { LoginValues } from '../pages/login';
import { axios } from '../../../technical/network';
import {
  IUserLoginV1Response,
  IUserRequestPasswordChangeV1Response,
  IUserV1Response,
  IUserDetailedV1Response,
} from 'common-active-invest-supervision/dist/src/business/user/api/v1';
import { ForgotPasswordValues } from '../pages/forgot-password';
import i18n from 'i18next';
import { UserAddValues } from '../pages/add/form';
import { UserRole } from 'common-active-invest-supervision/dist/src/business/user/types';
import queryString from 'query-string';
/**
 * Fetch my user
 */
export async function getUserMe(
  sessionId: string,
): Promise<{
  sessionId: string;
  data: IUserDetailedV1Response;
}> {
  if (sessionId) {
    const response = await axios.get<IUserDetailedV1Response>('/user/me');

    return Promise.resolve({
      sessionId,
      data: response.data,
    });
  }

  return Promise.reject();
}

export async function getUser(id: string): Promise<IUserDetailedV1Response> {
  const response = await axios.get(`/user/${id}`);

  return response.data;
}

export async function updateUser(
  id: string,
  values: UserAddValues,
): Promise<void> {
  const response = await axios.put<IUserV1Response>(`/user/${id}`, {
    ...values,
    email: values.email.length === 0 ? null : values.email,
    phone: values.phone.length === 0 ? null : values.phone,
  });
  if (response.status !== 200) {
    throw response;
  }
}

const USER_DELETE_REQUEST_FAILED = 'USER_DELETE_REQUEST_FAILED';
export async function deleteUser(id: string): Promise<void> {
  const response = await axios.delete<void>(`/user/${id}`);
  if (response.status !== 204) {
    throw new Error(USER_DELETE_REQUEST_FAILED);
  }
}

export async function login(
  values: LoginValues,
): Promise<IUserLoginV1Response> {
  const response = await axios.post<IUserLoginV1Response>(
    `/user/login`,
    values,
  );
  if (response.status !== 200) {
    throw response.data;
  }

  return response.data;
}

export const LOGOUT_REQUEST_FAILED = 'LOGOUT_REQUEST_FAILED';
export async function logout(): Promise<void> {
  const response = await axios.post<null>('/user/logout');
  if (![204, 401].includes(response.status)) {
    throw new Error(LOGOUT_REQUEST_FAILED);
  }
}

export async function requestChangePassword(
  values: ForgotPasswordValues,
): Promise<void> {
  const response = await axios.post<IUserRequestPasswordChangeV1Response>(
    '/user/requestPasswordChange',
    values,
  );
  if (response.status !== 200) {
    throw new Error(
      response.data.errors
        ? response.data.errors.email
        : i18n.t('user.forgot-password.form.error'),
    );
  }
}

export async function post(values: UserAddValues) {
  const response = await axios.post<IUserV1Response>('/user', {
    ...values,
    email: values.email.length === 0 ? null : values.email,
    phone: values.phone.length === 0 ? null : values.phone,
  });
  if (response.status !== 201) {
    throw response;
  }

  return response.data;
}

export async function listUsers(params: {
  role?: UserRole;
  search?: string;
  limit?: number;
  page?: number;
}): Promise<{
  items: IUserV1Response[];
  metadata: {
    currentPage: number;
    pageCount: number;
  };
}> {
  const filter = {
    role: params.role ? params.role : undefined,
    search: params.search ? params.search : undefined,
    limit: params.limit ? params.limit : undefined,
    page: params.page ? params.page : 1,
  };
  const stringifiedQuery = queryString.stringify(filter);

  const response = await axios.get(
    `/users${stringifiedQuery ? `?${stringifiedQuery}` : ''}`,
  );

  return response.data;
}

export async function patchUserLocale(id: string, locale: string) {
  const response = await axios.patch<IUserV1Response>(`/user/${id}`, {
    locale,
  });

  if (response.status !== 200) {
    throw response;
  }

  return response.data;
}
