import React, { useState, useEffect } from 'react';
import { Field, Form, Formik } from 'formik';
import { InputText } from '../../../../ui/form/inputText';

import { useTranslation } from 'react-i18next';
import { UserRole } from 'common-active-invest-supervision/dist/src/business/user/types';
import styles from './form.module.scss';
import { post, DevicePostType, updateDevice } from '../../services/api';
import classNames from 'classnames';
import { SectionTitle } from 'ui/typo/section-title';
import { useUserContext } from 'business/user/states';
import { Select } from 'ui/form/select';
import { Modal } from 'ui/modal';
import { Icon } from 'ui/icons';
import { IDeviceTypeV1Response } from 'common-active-invest-supervision/dist/src/business/deviceType/api/v1';
import { ISiteV1Response } from 'common-active-invest-supervision/dist/src/business/site/api/v1';
import { IUserV1Response } from 'common-active-invest-supervision/dist/src/business/user/api/v1';
import { listDeviceTypes } from 'business/device-type/services/api';
import { listSites } from 'business/site/services/api';
import { listUsers } from 'business/user/services/api';
import FormDeviceTypeAdd from 'business/device-type/pages/add/form';
import FormSiteAdd from 'business/site/pages/add/form';
import FormUserAdd from 'business/user/pages/add/form';
import { Loader } from 'ui/loader';
import { ProjectMap } from 'ui/google-maps/project-map';
import { FRANCE_REGION } from 'technical/google-maps/constants';
import { Button } from 'ui/button/button';
import { SimpleMarker } from 'ui/google-maps/markers/simple';
import { deviceAddValidation } from 'business/device/pages/add/service';
import { useNavigate } from 'react-router';
import { Routes } from 'bootstrap/router';

export type DeviceAddValues = {
  deviceTypeId: string;
  maintainerId: string | null;
  device: string;
  description: string;
  siteId: string;
  latitude?: number;
  longitude?: number;
  altitude?: number;
  precision?: number;
  dashboardId: string;
};

interface Props {
  modifyId?: string;
  initialData?: DeviceAddValues;
}

let timeout: number | null = null;

export default function FormDeviceAdd({ modifyId, initialData }: Props) {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);
  const { user } = useUserContext();
  const [deviceTypes, setDeviceTypes] = useState<IDeviceTypeV1Response[]>([]);
  const [sites, setSites] = useState<ISiteV1Response[]>([]);
  const [maintainers, setMaintainers] = useState<IUserV1Response[]>([]);
  const [isFetching, setIsFetching] = useState<boolean>(false);
  const [modalDeviceTypeOpen, setModalDeviceTypeOpen] = useState<boolean>(
    false,
  );
  const [modalSiteOpen, setModalSiteOpen] = useState<boolean>(false);
  const [modalUserOpen, setModalUserOpen] = useState<boolean>(false);
  const [
    markerCoordinates,
    setMarkerCoordinates,
  ] = useState<google.maps.LatLngLiteral | null>(
    initialData
      ? {
          lat: Number(initialData.latitude),
          lng: Number(initialData.longitude),
        }
      : null,
  );

  useEffect(() => {
    async function getDeviceTypes() {
      const { items } = await listDeviceTypes({ page: 1, limit: 100 });
      setDeviceTypes(items);
    }

    async function getSites() {
      const { items } = await listSites({ page: 1, limit: 100 });
      setSites(items);
    }

    async function getMaintainers() {
      const { items } = await listUsers({ role: UserRole.MAINTAINER });
      setMaintainers(items);
    }

    setIsFetching(true);
    Promise.all([getDeviceTypes(), getSites(), getMaintainers()])
      .catch(err => console.error(err))
      .finally(() => setIsFetching(false));
  }, []);

  return (
    <>
      <SectionTitle
        text={
          modifyId && initialData
            ? t('device.modify.title', {
                name: initialData.description,
              })
            : t('device.add.title')
        }
      />

      {isFetching ? (
        <Loader />
      ) : (
        <Formik<DeviceAddValues>
          initialValues={
            initialData || {
              deviceTypeId: '',
              maintainerId: '',
              device: '',
              description: '',
              siteId: '',
              latitude: undefined,
              longitude: undefined,
              altitude: undefined,
              precision: undefined,
              dashboardId: '',
            }
          }
          onSubmit={async (values: DeviceAddValues) => {
            setLoading(true);
            setError(null);
            try {
              const valueToPost: DevicePostType = {
                deviceTypeId: values.deviceTypeId,
                maintainerId: values.maintainerId,
                device: values.device,
                description: values.description,
                siteId: values.siteId,
                dashboardId: values.dashboardId,
              };
              if (
                values.latitude ||
                values.longitude ||
                values.altitude ||
                values.precision
              ) {
                valueToPost.location = {
                  position: {
                    x: values.latitude,
                    y: values.longitude,
                  },
                  altitude: values.altitude,
                  precision: values.precision,
                };
              }
              if (values.maintainerId) {
                valueToPost.maintainerId = values.maintainerId;
              }

              if (modifyId) {
                await updateDevice(modifyId, valueToPost);
                navigate(Routes.DeviceList, {
                  state: { flashMessageSuccess: 'device.modify.form.success' },
                });
              } else {
                await post(valueToPost);
                navigate(Routes.DeviceList, {
                  state: { flashMessageSuccess: 'device.add.form.success' },
                });
              }
            } catch (e) {
              setError(
                t(
                  modifyId
                    ? 'device.modify.form.error'
                    : 'device.add.form.error',
                ),
              );
            } finally {
              setLoading(false);
            }
          }}
          validate={values => deviceAddValidation(values, modifyId)}
        >
          {({ setFieldValue, submitForm }) => (
            <Form>
              <div className={styles.mainContainer}>
                <div className={styles.formContainer}>
                  <div className={styles.multipleOnLineNoMobile}>
                    <Icon width={16} height={16}>
                      help
                    </Icon>
                    <span className={styles.iconText}>
                      {t('device.add.form.information')}
                    </span>
                  </div>
                  <div className={styles.multipleOnLine}>
                    <div className={styles.canAddRow}>
                      <Field
                        className={styles.inputText}
                        name="deviceTypeId"
                        placeholder={t(
                          'device.add.form.deviceTypeId.placeholder',
                        )}
                        component={Select}
                        options={deviceTypes.map(
                          (deviceType: IDeviceTypeV1Response) => ({
                            value: deviceType.id,
                            label: deviceType.name,
                          }),
                        )}
                      />
                      <button
                        className={styles.addButton}
                        onClick={e => {
                          e.preventDefault();
                          setModalDeviceTypeOpen(true);
                        }}
                      >
                        +
                      </button>
                    </div>
                    <div className={styles.elementOfLine}>
                      <Field
                        className={styles.inputText}
                        name="device"
                        placeholder={t('device.add.form.device.placeholder')}
                        component={InputText}
                      />
                    </div>
                  </div>

                  <Field
                    name="description"
                    placeholder={t('device.add.form.description.placeholder')}
                    component={InputText}
                    className={classNames(styles.inputText, styles.fullWidth)}
                  />

                  <Field
                    name="dashboardId"
                    placeholder={t('device.add.form.dashboardId.placeholder')}
                    component={InputText}
                    className={classNames(styles.inputText, styles.fullWidth)}
                  />

                  <div
                    className={classNames(
                      styles.multipleOnLineNoMobile,
                      styles.marginTop,
                    )}
                  >
                    <Icon width={16} height={16}>
                      location
                    </Icon>
                    <span className={styles.iconText}>
                      {t('device.add.form.location')}
                    </span>
                  </div>

                  <div className={classNames(styles.canAddRow)}>
                    <Field
                      className={styles.inputText}
                      name="siteId"
                      placeholder={t('device.add.form.siteId.placeholder')}
                      component={Select}
                      options={sites.map((site: ISiteV1Response) => ({
                        value: site.id,
                        label: site.name,
                      }))}
                    />
                    <button
                      className={styles.addButton}
                      onClick={e => {
                        e.preventDefault();
                        setModalSiteOpen(true);
                      }}
                    >
                      +
                    </button>
                  </div>

                  <div className={styles.multipleOnLine}>
                    <div className={styles.marginRight}>
                      <Field
                        name="latitude"
                        type="number"
                        step="any"
                        placeholder={t('device.add.form.latitude.placeholder')}
                        component={InputText}
                        className={styles.inputText}
                      />
                    </div>

                    <Field
                      name="longitude"
                      type="number"
                      step="any"
                      placeholder={t('device.add.form.longitude.placeholder')}
                      component={InputText}
                      className={styles.inputText}
                    />
                  </div>
                  <div className={styles.multipleOnLine}>
                    <div className={styles.marginRight}>
                      <Field
                        name="altitude"
                        type="number"
                        step="any"
                        placeholder={t('device.add.form.altitude.placeholder')}
                        component={InputText}
                        className={styles.inputText}
                      />
                    </div>
                    <Field
                      name="precision"
                      type="number"
                      step="any"
                      placeholder={t('device.add.form.precision.placeholder')}
                      component={InputText}
                      className={styles.inputText}
                    />
                  </div>

                  <div
                    className={classNames(
                      styles.multipleOnLineNoMobile,
                      styles.marginTop,
                    )}
                  >
                    <Icon width={16} height={16}>
                      settings
                    </Icon>
                    <span className={styles.iconText}>
                      {t('device.add.form.maintainer')}
                    </span>
                  </div>

                  <div className={classNames(styles.canAddRow)}>
                    <Field
                      className={styles.inputText}
                      name="maintainerId"
                      placeholder={t(
                        'device.add.form.maintainerId.placeholder',
                      )}
                      component={Select}
                      options={maintainers.map(
                        (maitainer: IUserV1Response) => ({
                          value: maitainer.id,
                          label: `${maitainer.firstName} ${maitainer.lastName} <${maitainer.email}>`,
                        }),
                      )}
                    />
                    <button
                      className={styles.addButton}
                      onClick={e => {
                        e.preventDefault();
                        setModalUserOpen(true);
                      }}
                    >
                      +
                    </button>
                  </div>
                  <div className={styles.actions}>
                    <Button
                      disabled={loading}
                      type="submit"
                      onClick={submitForm}
                    >
                      {t(
                        modifyId
                          ? 'device.modify.form.submit'
                          : 'device.add.form.submit',
                      )}
                    </Button>
                  </div>
                  {error !== null && (
                    <div className={classNames(styles.errors)}>{error}</div>
                  )}
                </div>
                <ProjectMap
                  mapContainerClassName={styles.mapContainer}
                  center={FRANCE_REGION.center}
                  zoom={FRANCE_REGION.zoom}
                  onClick={e => {
                    const clickedLatitude = e.latLng?.lat();
                    const clickedLongitude = e.latLng?.lng();

                    // "onClick" event is triggered both on simple click and double click.
                    // To keep the "zoom on double click" behaviour, we set a timeout "onClick" and remove it "onDblClick" if the click was double.
                    if (clickedLatitude && clickedLongitude) {
                      timeout = window.setTimeout(() => {
                        setMarkerCoordinates({
                          lat: clickedLatitude,
                          lng: clickedLongitude,
                        });
                        setFieldValue('latitude', clickedLatitude);
                        setFieldValue('longitude', clickedLongitude);
                      }, 300);
                    }
                  }}
                  onDblClick={() => {
                    if (timeout) {
                      clearTimeout(timeout);
                    }
                  }}
                >
                  {markerCoordinates ? (
                    <SimpleMarker {...markerCoordinates} />
                  ) : null}
                </ProjectMap>
              </div>
            </Form>
          )}
        </Formik>
      )}

      <Modal
        isOpen={modalDeviceTypeOpen}
        onRequestClose={() => setModalDeviceTypeOpen(false)}
      >
        <FormDeviceTypeAdd
          onAdded={createdDeviceType => {
            setModalDeviceTypeOpen(false);
            setDeviceTypes([...deviceTypes, createdDeviceType]);
          }}
        />
      </Modal>

      <Modal
        isOpen={modalSiteOpen}
        onRequestClose={() => setModalSiteOpen(false)}
      >
        <FormSiteAdd
          onAdded={createdSite => {
            setModalSiteOpen(false);
            setSites([...sites, createdSite]);
          }}
          canCreateEntity={false}
        />
      </Modal>

      <Modal
        isOpen={modalUserOpen}
        onRequestClose={() => setModalUserOpen(false)}
      >
        {user && (
          <FormUserAdd
            onAdded={createdUser => {
              setModalUserOpen(false);
              if (createdUser.role === UserRole.MAINTAINER) {
                setMaintainers([...maintainers, createdUser]);
              }
            }}
            isInsideModal
          />
        )}
      </Modal>
    </>
  );
}
