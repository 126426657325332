import { Routes } from 'bootstrap/router';
import { listClients } from 'business/client/services/api';
import { listDeviceTypes } from 'business/device-type/services/api';
import { fetchDevicesLight } from 'business/device/services/api';
import { getMaxSeverity } from 'business/device/services/getMaxSeverity';
import { listSites } from 'business/site/services/api';
import { ConnectedContainer } from 'business/user/containers/connectedOrNotContainers';
import { useUserContext } from 'business/user/states';
import { ALERT_SEVERITY } from 'common-active-invest-supervision/dist/src/business/alert/types';
import { IClientDetailedV1Response } from 'common-active-invest-supervision/dist/src/business/client/api/v1';
import { COMPANY_DEFAULT_CATEGORY_DEVICE } from 'common-active-invest-supervision/dist/src/business/company/types';
import { IDeviceV1ResponseLight } from 'common-active-invest-supervision/dist/src/business/device/api/v1';
import { IDeviceTypeV1Response } from 'common-active-invest-supervision/dist/src/business/deviceType/api/v1';
import { ISiteDetailV1Response } from 'common-active-invest-supervision/dist/src/business/site/api/v1';
import { UserRole } from 'common-active-invest-supervision/dist/src/business/user/types';
import { Formik } from 'formik';
import queryString from 'query-string';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router';
import { useMediaType } from 'technical/media/hooks';
import { AddButton } from 'ui/button/add-button';
import { Button } from 'ui/button/button';
import { DeviceStatus } from 'ui/device-status';
import { Icon } from 'ui/icons';
import { Separator } from 'ui/layout/separator';
import { Loader } from 'ui/loader';
import { Modal } from 'ui/modal';
import { SectionTitle } from 'ui/typo/section-title';
import BasePage from '../../../base-page';
import DevicesMap from './devices-map';
import FilterForm from './filter-form';
import styles from './index.module.scss';
import { InfoMessage } from 'ui/info-message';

function countDeviceType(devices: IDeviceV1ResponseLight[]) {
  const count = {
    [COMPANY_DEFAULT_CATEGORY_DEVICE.BATTERY]: 0,
    [`${COMPANY_DEFAULT_CATEGORY_DEVICE.BATTERY}_okAlarms`]: 0,
    [`${COMPANY_DEFAULT_CATEGORY_DEVICE.BATTERY}_criticalAlarms`]: 0,
    [`${COMPANY_DEFAULT_CATEGORY_DEVICE.BATTERY}_majorAlarms`]: 0,
    [`${COMPANY_DEFAULT_CATEGORY_DEVICE.BATTERY}_minorAlarms`]: 0,

    [COMPANY_DEFAULT_CATEGORY_DEVICE.ELECTRICAL_CABINET]: 0,
    [`${COMPANY_DEFAULT_CATEGORY_DEVICE.ELECTRICAL_CABINET}_okAlarms`]: 0,
    [`${COMPANY_DEFAULT_CATEGORY_DEVICE.ELECTRICAL_CABINET}_criticalAlarms`]: 0,
    [`${COMPANY_DEFAULT_CATEGORY_DEVICE.ELECTRICAL_CABINET}_majorAlarms`]: 0,
    [`${COMPANY_DEFAULT_CATEGORY_DEVICE.ELECTRICAL_CABINET}_minorAlarms`]: 0,

    [COMPANY_DEFAULT_CATEGORY_DEVICE.LIGHT]: 0,
    [`${COMPANY_DEFAULT_CATEGORY_DEVICE.LIGHT}_okAlarms`]: 0,
    [`${COMPANY_DEFAULT_CATEGORY_DEVICE.LIGHT}_criticalAlarms`]: 0,
    [`${COMPANY_DEFAULT_CATEGORY_DEVICE.LIGHT}_majorAlarms`]: 0,
    [`${COMPANY_DEFAULT_CATEGORY_DEVICE.LIGHT}_minorAlarms`]: 0,
  };
  devices.forEach(device => {
    count[device.category] += 1;
    const severity = getMaxSeverity(device);
    switch (severity) {
      case ALERT_SEVERITY.CRITICAL: {
        count[`${device.category}_criticalAlarms`] += 1;
        break;
      }
      case ALERT_SEVERITY.MAJOR: {
        count[`${device.category}_majorAlarms`] += 1;
        break;
      }
      case ALERT_SEVERITY.MINOR: {
        count[`${device.category}_minorAlarms`] += 1;
        break;
      }
      default: {
        count[`${device.category}_okAlarms`] += 1;
        break;
      }
    }
  });

  return count;
}

function DeviceList() {
  const [devices, setDevices] = useState<IDeviceV1ResponseLight[]>([]);
  const [sites, setSites] = useState<ISiteDetailV1Response[]>([]);
  const [deviceTypes, setDeviceTypes] = useState<IDeviceTypeV1Response[]>([]);
  const [clients, setClients] = useState<IClientDetailedV1Response[]>([]);
  const [mobileMenuOpened, setMobileMenuOpened] = useState<boolean>(false);
  const [overviewOpened, setOverviewOpened] = useState<boolean>(false);

  const { t } = useTranslation();
  const { user } = useUserContext();
  const navigate = useNavigate();
  const { search } = useLocation();
  const { isMobile } = useMediaType();

  const urlSearchParams = new URLSearchParams(search);
  const initialClient = urlSearchParams.get('client');
  const initialSite = urlSearchParams.get('site');
  const initialDeviceType = urlSearchParams.get('deviceType');

  const [isFetching, setIsFetching] = useState<boolean>(false);

  useEffect(() => {
    async function fetchData() {
      try {
        setIsFetching(true);
        const [devices, clients, sites, deviceTypes] = await Promise.all([
          fetchDevicesLight(),
          listClients({ limit: 100 }),
          listSites({ limit: 100 }),
          listDeviceTypes({ limit: 100 }),
        ]);
        if (devices) {
          setDevices(devices.items);
        }
        setClients(clients.items);
        setSites(sites.items);
        setDeviceTypes(deviceTypes.items);
      } catch (err) {
        console.error(err);
      } finally {
        setIsFetching(false);
      }
    }

    fetchData();
  }, []);

  const authorizedToAdd = [UserRole.COMPANY_MANAGER];
  const buttons =
    user && authorizedToAdd.includes(user.role) ? (
      <>
        <AddButton
          className={styles.buttonMobile}
          onClick={() => navigate(Routes.DeviceAdd)}
        >
          {t('device.list.add')}
        </AddButton>
      </>
    ) : null;

  const deviceStatus = (numberOfDevices: { [x: string]: number }) => (
    <>
      {!!numberOfDevices[
        COMPANY_DEFAULT_CATEGORY_DEVICE.ELECTRICAL_CABINET
      ] && (
        <div className={styles.deviceStatus} title={t('device.list.cabinet')}>
          <DeviceStatus
            okCount={
              numberOfDevices[
                `${COMPANY_DEFAULT_CATEGORY_DEVICE.ELECTRICAL_CABINET}_okAlarms`
              ]
            }
            criticalCount={
              numberOfDevices[
                `${COMPANY_DEFAULT_CATEGORY_DEVICE.ELECTRICAL_CABINET}_criticalAlarms`
              ]
            }
            majorCount={
              numberOfDevices[
                `${COMPANY_DEFAULT_CATEGORY_DEVICE.ELECTRICAL_CABINET}_majorAlarms`
              ]
            }
            minorCount={
              numberOfDevices[
                `${COMPANY_DEFAULT_CATEGORY_DEVICE.ELECTRICAL_CABINET}_minorAlarms`
              ]
            }
            total={
              numberOfDevices[
                COMPANY_DEFAULT_CATEGORY_DEVICE.ELECTRICAL_CABINET
              ]
            }
            icon="cabinet"
          />
        </div>
      )}
      {!!numberOfDevices[COMPANY_DEFAULT_CATEGORY_DEVICE.LIGHT] && (
        <div className={styles.deviceStatus} title={t('device.list.lamp')}>
          <DeviceStatus
            okCount={
              numberOfDevices[
                `${COMPANY_DEFAULT_CATEGORY_DEVICE.LIGHT}_okAlarms`
              ]
            }
            criticalCount={
              numberOfDevices[
                `${COMPANY_DEFAULT_CATEGORY_DEVICE.LIGHT}_criticalAlarms`
              ]
            }
            majorCount={
              numberOfDevices[
                `${COMPANY_DEFAULT_CATEGORY_DEVICE.LIGHT}_majorAlarms`
              ]
            }
            minorCount={
              numberOfDevices[
                `${COMPANY_DEFAULT_CATEGORY_DEVICE.LIGHT}_minorAlarms`
              ]
            }
            total={numberOfDevices[COMPANY_DEFAULT_CATEGORY_DEVICE.LIGHT]}
            icon="lamp"
          />
        </div>
      )}
      {!!numberOfDevices[COMPANY_DEFAULT_CATEGORY_DEVICE.BATTERY] && (
        <div className={styles.deviceStatus} title={t('device.list.battery')}>
          <DeviceStatus
            okCount={
              numberOfDevices[
                `${COMPANY_DEFAULT_CATEGORY_DEVICE.BATTERY}_okAlarms`
              ]
            }
            criticalCount={
              numberOfDevices[
                `${COMPANY_DEFAULT_CATEGORY_DEVICE.BATTERY}_criticalAlarms`
              ]
            }
            majorCount={
              numberOfDevices[
                `${COMPANY_DEFAULT_CATEGORY_DEVICE.BATTERY}_majorAlarms`
              ]
            }
            minorCount={
              numberOfDevices[
                `${COMPANY_DEFAULT_CATEGORY_DEVICE.BATTERY}_minorAlarms`
              ]
            }
            total={numberOfDevices[COMPANY_DEFAULT_CATEGORY_DEVICE.BATTERY]}
            icon="battery"
          />
        </div>
      )}
    </>
  );

  return (
    <ConnectedContainer>
      <BasePage
        className={styles.container}
        footer={
          <>
            {buttons}
            <Button
              colorMode="stroke"
              className={styles.buttonMobile}
              onClick={() => {
                setOverviewOpened(!overviewOpened);
                setMobileMenuOpened(false);
              }}
            >
              {t(
                overviewOpened
                  ? 'device.list.seeMap'
                  : 'device.list.seeWeather',
              )}
            </Button>
          </>
        }
      >
        <div>
          <InfoMessage />

          <Formik
            initialValues={{
              client: initialClient,
              site: initialSite,
              deviceType: initialDeviceType,
            }}
            onSubmit={() => {}}
          >
            {({ values: { client, site, deviceType } }) => {
              const currentFiltersConfigurationSearch = queryString.stringify({
                client,
                site,
                deviceType,
              });

              const filteredDevices = devices
                .filter(d => (client ? d.site.client.id === client : true))
                .filter(d => (site ? d.site.id === site : true))
                .filter(d =>
                  deviceType ? d.deviceType.id === deviceType : true,
                );

              return (
                <>
                  <div className={styles.head}>
                    <SectionTitle text={t('device.list.title')}>
                      {!overviewOpened && (
                        <div
                          className={styles.headIcon}
                          onClick={() => setMobileMenuOpened(true)}
                        >
                          <Icon width={32} height={32}>
                            filter
                          </Icon>
                        </div>
                      )}
                      {!isMobile && (
                        <div className={styles.weather}>
                          {!isMobile &&
                            deviceStatus(countDeviceType(filteredDevices))}
                        </div>
                      )}
                    </SectionTitle>
                  </div>
                  <Modal
                    isOpen={mobileMenuOpened}
                    onRequestClose={() => setMobileMenuOpened(false)}
                  >
                    <p>{t('device.list.filterBy')}</p>
                    <FilterForm
                      clients={clients}
                      sites={sites}
                      deviceTypes={deviceTypes}
                    />
                    <Button
                      className={styles.searchButton}
                      onClick={() => setMobileMenuOpened(false)}
                    >
                      {t('device.list.search')}
                    </Button>
                  </Modal>
                  <div className={styles.desktopOnly}>
                    <FilterForm
                      clients={clients}
                      sites={sites}
                      deviceTypes={deviceTypes}
                    />
                    {!isMobile && buttons}
                  </div>
                  {!isMobile && <Separator />}

                  {isFetching && <Loader />}

                  {!isFetching && !overviewOpened && (
                    <DevicesMap
                      devices={filteredDevices}
                      filtersSearch={currentFiltersConfigurationSearch}
                    />
                  )}

                  {overviewOpened && (
                    <div className={styles.deviceStatusContainer}>
                      {deviceStatus(countDeviceType(filteredDevices))}
                    </div>
                  )}
                </>
              );
            }}
          </Formik>
        </div>
      </BasePage>
    </ConnectedContainer>
  );
}

export default DeviceList;
