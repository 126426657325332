import React, { ReactElement } from 'react';
import { useUserContext } from '../states';
import { Loader } from 'ui/loader';
import { Navigate } from 'react-router-dom';
import { Routes } from 'bootstrap/router';

/**
 * Redirect the user to the default not-connected route when the user is not-connected and intend to access connected route
 */
export const ConnectedContainer: React.FC = ({ children }) => {
  const { isConnected, isLoading } = useUserContext();
  if (isLoading) {
    return <Loader />;
  }

  return isConnected ? (
    (children as ReactElement)
  ) : (
    <Navigate replace to={Routes.HomeNotConnected} />
  );
};

/**
 * Redirect the user to the default connected route when the user is connected and intend to access not connected route
 */
export const NotConnectedContainer: React.FC = ({ children }) => {
  const { isConnected } = useUserContext();

  return isConnected ? (
    <Navigate replace to={Routes.HomeConnected} />
  ) : (
    (children as ReactElement)
  );
};
