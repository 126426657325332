import i18n from 'i18next';
import validate from 'validate.js';
import {
  REQUIRED,
  NUMBER,
  NUMBER_GREATER,
} from '../../../../../technical/validation/rules';
import { AlertValues } from '../../types';

export const alertAddValidation = (values: AlertValues) => {
  const constraints = {
    name: REQUIRED(i18n.t('alert.add.form.name.required')),
    deviceTypeId: REQUIRED(i18n.t('alert.add.form.deviceTypeId.required')),
    severity: REQUIRED(i18n.t('alert.add.form.severity.required')),
    metric: REQUIRED(i18n.t('alert.add.form.metric.required')),
    thresholdValue:
      values.metric === 'inactive'
        ? '' // in case of inactivity there is no need to require a threshold
        : {
            ...REQUIRED(i18n.t('alert.add.form.thresholdValue.required')),
            ...NUMBER(i18n.t('alert.add.form.thresholdValue.error')),
          },
    triggerPeriod:
      values.metric === 'inactive'
        ? NUMBER_GREATER(
            i18n.t('alert.add.form.triggerPeriod.inactive.error'),
            i18n.t('alert.add.form.triggerPeriod.inactive.errorGreater'),
            5,
          )
        : NUMBER(i18n.t('alert.add.form.triggerPeriod.occurrences.error')),
  };

  return validate(values, constraints);
};
