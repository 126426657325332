export function factoryDateTime() {
  const today = new Date();
  // use UTC date to avoid timezone trouble
  return new DateTime(
    new Date(
      Date.UTC(
        today.getFullYear(),
        today.getMonth(),
        today.getDate(),
        today.getHours(),
        today.getMinutes(),
        today.getSeconds(),
      ),
    ),
  );
}

export class DateTime {
  now: Date;

  constructor(now: Date) {
    this.now = now;
  }

  public getNow() {
    return this.now;
  }

  // calculate x days ago (ex: 7 days ago)
  public subXDaysAgo(days: number): Date {
    const xDaysAgo = new Date(this.now.getTime() - days * 24 * 60 * 60 * 1000);
    return new Date(
      Date.UTC(xDaysAgo.getFullYear(), xDaysAgo.getMonth(), xDaysAgo.getDate()),
    );
  }
}
