import React from 'react';
import { useTranslation } from 'react-i18next';
import { UserRole } from 'common-active-invest-supervision/dist/src/business/user/types';
import styles from 'ui/styles/add-form.module.scss';
import { ConnectedContainer } from 'business/user/containers/connectedOrNotContainers';
import { useUserContext } from 'business/user/states';
import { Navigate, generatePath, useNavigate, useParams } from 'react-router';
import BasePage from 'business/base-page';
import FormArtifactAdd from '../../../form';
import { Routes } from 'bootstrap/router';
import { invariant } from 'technical/router/query-params';

const FirmwareAdd: React.FC = () => {
  const { t } = useTranslation();
  const { user } = useUserContext();
  const { id } = useParams<{ id: string }>();
  invariant(id);
  const navigate = useNavigate();

  if (user && user.role !== UserRole.COMPANY_MANAGER) {
    return (
      <Navigate replace to={generatePath(Routes.DeviceTypeView, { id })} />
    );
  }

  return (
    <ConnectedContainer>
      <BasePage className={styles.container}>
        <button onClick={() => navigate(-1)} className={styles.returnButton}>
          &lt; {t('common.return')}
        </button>
        <FormArtifactAdd deviceTypeId={id} artifactType="firmware" />
      </BasePage>
    </ConnectedContainer>
  );
};

export default FirmwareAdd;
