import React, { useState, useEffect } from 'react';
import BasePage from '../../../base-page';
import styles from 'ui/styles/list.module.scss';
import { Row } from 'ui/table';
import { SectionTitle } from 'ui/typo/section-title';
import { useTranslation } from 'react-i18next';

import { useMediaType } from 'technical/media/hooks';
import { Modal } from 'ui/modal';
import { Routes } from 'bootstrap/router';
import { ConnectedContainer } from 'business/user/containers/connectedOrNotContainers';

import moment from 'moment';
import { useUserContext } from 'business/user/states';
import { UserRole } from 'common-active-invest-supervision/dist/src/business/user/types';
import { Paginator } from 'ui/paginator';
import { listClients } from 'business/client/services/api';
import { IClientDetailedV1Response } from 'common-active-invest-supervision/dist/src/business/client/api/v1';
import { Loader } from 'ui/loader';
import { NoResults } from 'ui/no-results';
import { Button } from 'ui/button/button';
import { AddButton } from 'ui/button/add-button';
import { Navigate, generatePath, useNavigate } from 'react-router';
import { InfoMessage } from 'ui/info-message';

export const authorizedToAdd = [UserRole.COMPANY_MANAGER];

const ClientList: React.FC = () => {
  const { t } = useTranslation();
  const [clients, setClients] = useState<IClientDetailedV1Response[]>([]);
  const [page, setPage] = useState<number>(1);
  const [totalPage, setTotalPage] = useState<number>(0);
  const [searchModalOpen, setSearchModalOpen] = useState<boolean>(false);
  const navigate = useNavigate();
  const { isMobile } = useMediaType();
  const { user } = useUserContext();
  const [isFetching, setIsFetching] = useState<boolean>(false);

  useEffect(() => {
    async function getClients() {
      try {
        setIsFetching(true);
        const { items, metadata } = await listClients({ page });
        setClients(items);
        setTotalPage(metadata.pageCount);
      } catch (err) {
        console.error(err);
      } finally {
        setIsFetching(false);
      }
    }

    getClients();
  }, [page]);

  const authorizedUserGroups = [
    UserRole.GROUP_MANAGER,
    UserRole.GROUP_OBSERVER,
    UserRole.COMPANY_MANAGER,
    UserRole.COMPANY_OBSERVER,
    UserRole.CLIENT_MANAGER,
    UserRole.CLIENT_OBSERVER,
  ];
  if (user && !authorizedUserGroups.includes(user.role)) {
    return <Navigate replace to={Routes.Home} />;
  }

  const formatDate = (date: Date | string) => {
    return moment(date).format('LLL');
  };

  const buttons =
    user && authorizedToAdd.includes(user.role) ? (
      <>
        <AddButton onClick={() => navigate(Routes.ClientAdd)}>
          {t('client.list.add')}
        </AddButton>
      </>
    ) : null;

  return (
    <ConnectedContainer>
      <BasePage className={styles.container} footer={buttons}>
        <div>
          <SectionTitle text={t('client.list.title')} />
          <div className={styles.actionContainer}>{!isMobile && buttons}</div>

          <InfoMessage />

          <Loader isLoading={isFetching} />

          <NoResults isVisible={!isFetching && !clients.length} />

          {clients.map(client => (
            <Row
              key={client.id}
              mobileColumns={[0]}
              rowElements={[
                {
                  text: client.name,
                  subText: client.company.name,
                  flex: 2,
                },
                {
                  subText: `${t('client.list.created')} ${formatDate(
                    client.creationTime,
                  )} \n${t('client.list.updated')} ${formatDate(
                    client.updateTime,
                  )}`,
                  flex: 2,
                },
              ]}
              onClick={() =>
                navigate(generatePath(Routes.ClientView, { id: client.id }))
              }
            />
          ))}
          {totalPage > 0 && (
            <Paginator setPage={setPage} totalPage={totalPage} page={page} />
          )}
        </div>
        <Modal
          isOpen={searchModalOpen}
          onRequestClose={() => setSearchModalOpen(false)}
        >
          <Button
            className={styles.buttonModal}
            onClick={() => navigate(Routes.ClientAdd)}
          >
            {t('client.list.add')}
          </Button>
        </Modal>
      </BasePage>
    </ConnectedContainer>
  );
};

export default ClientList;
