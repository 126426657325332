import React, { useState } from 'react';
import { Field, Formik } from 'formik';
import { InputText } from '../../../../ui/form/inputText';

import { useTranslation } from 'react-i18next';
import { clientAddValidation } from './service';
import { UserRole } from 'common-active-invest-supervision/dist/src/business/user/types';
import { IClientV1Response } from 'common-active-invest-supervision/dist/src/business/client/api/v1';
import styles from '../../../../ui/styles/add-form.module.scss';
import { post, updateClient } from '../../services/api';
import classNames from 'classnames';
import { SectionTitle } from 'ui/typo/section-title';
import { useUserContext } from 'business/user/states';
import { redirectWithSuccess } from 'technical/flash-message';
import { Button } from 'ui/button/button';
import { InputCheckbox } from 'ui/form/inputCheckbox/inputCheckbox';
import { Routes } from 'bootstrap/router';
import { Navigate } from 'react-router';

export type ClientAddValues = {
  name: string;
  customAlertsEnabled: boolean;
};

export default function FormClientAdd(props: {
  onAdded?: (createdClient: IClientV1Response) => void;
  modifyId?: string;
  initialData?: ClientAddValues;
}) {
  const { t } = useTranslation();
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);
  const { user } = useUserContext();

  if (
    user &&
    ![UserRole.COMPANY_MANAGER, UserRole.CLIENT_MANAGER].includes(user.role)
  ) {
    return <Navigate replace to={Routes.Home} />;
  }

  return (
    <>
      <SectionTitle
        text={props.modifyId ? t('client.modify.title') : t('client.add.title')}
      />
      <div>
        <Formik<ClientAddValues>
          initialValues={
            props.initialData
              ? props.initialData
              : {
                  name: '',
                  customAlertsEnabled: false,
                }
          }
          onSubmit={async (values: ClientAddValues) => {
            setLoading(true);
            setError(null);
            try {
              if (props.modifyId) {
                await updateClient(props.modifyId, values);
                redirectWithSuccess('/clients', 'client.modify.form.success');
              } else {
                const createdClient = await post(values);
                props.onAdded
                  ? props.onAdded(createdClient)
                  : redirectWithSuccess('/clients', 'client.add.form.success');
              }
            } catch (e) {
              setError(t('client.add.form.error'));
            } finally {
              setLoading(false);
            }
          }}
          validate={clientAddValidation}
        >
          {({ values, submitForm }) => (
            <>
              <Field
                name="name"
                placeholder={t('client.add.form.name.placeholder')}
                component={InputText}
                className={styles.inputText}
              />
              {user && user.role === UserRole.COMPANY_MANAGER && (
                <Field
                  name="customAlertsEnabled"
                  placeholder={t('client.add.form.customAlertsEnabled')}
                  component={InputCheckbox}
                  className={styles.inputText}
                  defaultChecked={values.customAlertsEnabled}
                />
              )}
              <div className={styles.actions}>
                <Button disabled={loading} type="submit" onClick={submitForm}>
                  {props.modifyId
                    ? t('client.modify.form.submit')
                    : t('client.add.form.submit')}
                </Button>
              </div>
              {error !== null && (
                <div className={classNames(styles.errors)}>{error}</div>
              )}
            </>
          )}
        </Formik>
      </div>
    </>
  );
}
