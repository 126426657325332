import validate from 'validate.js';

export type TRequired = {
  presence: { message: string; allowEmpty: boolean };
};

export type TNumber = {
  numericality: {
    onlyInteger: false;
    notValid: string;
    noStrings: true;
  };
};

export type TEmail = {
  email: { message: string };
};

export type TLogin = {
  format: {
    message: string;
    pattern: RegExp;
    flags: 'i';
  };
};

export type TEquality = {
  equality: {
    message: string;
    attribute: string;
  };
};

// The declaration file does not support this field
(validate as any).options = {
  fullMessages: false,
};

export const REQUIRED = (message: string): TRequired => ({
  presence: { message, allowEmpty: false },
});

export const ALPHABETICAL = (message: string) => ({
  format: {
    message,
    pattern: /^[^±!@£$%^&*+§¡€#¢§¶•ªº«\\/<>?:;|=€©\d]{0,250}$/,
    flags: 'i',
  },
});

export const NUMERIC = (message: string) => ({
  numericality: {
    onlyInteger: true,
    notValid: message,
    notInteger: message,
    notGreaterThanOrEqualTo: message,
    notLessThanOrEqualTo: message,
  },
});

export const INTEGER = {
  numericality: {
    onlyInteger: true,
    notValid: 'wrong_number_format',
    notInteger: 'wrong_number_format',
    notGreaterThanOrEqualTo: 'not_gte',
    notLessThanOrEqualTo: 'not_lte',
    noStrings: true,
  },
};

export const NUMBER = (message: string): TNumber => ({
  numericality: {
    onlyInteger: false,
    notValid: message,
    noStrings: true,
  },
});

export const NUMBER_GREATER = (
  messageNotANumber: string,
  messageNotGreater: string,
  than: number,
) => ({
  numericality: {
    greaterThanOrEqualTo: than,
    notGreaterThanOrEqualTo: messageNotGreater,
    notValid: messageNotANumber,
  },
});

export const PHONE_NUMBER = (message: string) => ({
  format: {
    message,
    pattern: /^(\+33)(6|7)[0-9]{8}$/,
  },
});

export const NO_SPECIAL_CHARACTER = (message: string) => ({
  format: {
    message,
    pattern: /^[0-9a-zA-Z]{1,250}$/,
  },
});

export const SOCIAL_SECURITY_NUMBER = (message: string) => ({
  format: {
    message,
    pattern: /^(1|2)[0-9]{12}$/,
  },
});

export const BOOLEAN = {
  inclusion: {
    within: [true, false],
    message: 'wrong_boolean_format',
  },
};

export const INTEGER_RANGE = (min: number, max: number, message: string) => ({
  numericality: {
    ...NUMERIC(message).numericality,
    message,
    greaterThanOrEqualTo: min,
    lessThanOrEqualTo: max,
  },
});

export const LENGTH_RANGE = (
  min: number,
  max: number,
  messageShort: string,
  messageLong: string,
) => ({
  length: {
    minimum: min,
    maximum: max,
    tooShort: messageShort,
    tooLong: messageLong,
    getVariables: () => ({
      minimum: min,
      maximum: max,
    }),
  },
});

export const EMAIL = (message: string): TEmail => ({
  email: { message },
});

export const LOGIN = (message: string): TLogin => ({
  format: {
    message,
    pattern: /^[a-zA-Z0-9_+-.!#$'^`~@]+$/,
    flags: 'i',
  },
});

export const ENUM = (enumObject: any) => ({
  inclusion: { within: Object.values(enumObject), message: 'not_in_enum' },
});

/**
 * Validates if password has 8 characeters, 1 CAP, 1 Lower case and 1 number
 */
validate.validators.password = (
  password: string,
  options: { [key: string]: string },
): string | void => {
  const passwordValidator = new RegExp(
    /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[a-zA-Z\d]{8,}$/,
  );
  if (!passwordValidator.test(password)) {
    return options.message;
  }
};

/**
 * Defines the ISPASSWORD validation rule, passes a { message } options object to the validator passwor
 */
export const ISPASSWORD = (message: string) => ({
  password: { message },
});

/**
 * Defines the EQUALITY validation rule
 */
export const EQUALITY = (key: string, message: string): TEquality => ({
  equality: {
    message,
    attribute: key,
  },
});
