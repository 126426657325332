import React, { useEffect, useState } from 'react';
import BasePage from '../../../base-page';
import { useTranslation } from 'react-i18next';
import { ConnectedContainer } from 'business/user/containers/connectedOrNotContainers';
import styles from 'ui/styles/view.module.scss';
import { SectionTitle } from 'ui/typo/section-title';

import { generatePath, useNavigate, useParams } from 'react-router';
import { Icon } from 'ui/icons';
import { formatDateLLL } from 'technical/date';
import { useUserContext } from 'business/user/states';
import { UserRole } from 'common-active-invest-supervision/dist/src/business/user/types';
import { getClient, deleteClient } from 'business/client/services/api';
import { IClientDetailedV1Response } from 'common-active-invest-supervision/dist/src/business/client/api/v1';
import { Loader } from 'ui/loader';
import {
  redirectWithSuccess,
  redirectWithError,
} from 'technical/flash-message';
import { DeleteButton } from 'ui/button/delete-button';
import { ModifyButton } from 'ui/button/modify-button';
import { Routes } from 'bootstrap/router';
import { invariant } from 'technical/router/query-params';

const ClientView: React.FC = () => {
  const { t } = useTranslation();
  const { id } = useParams<{ id: string }>();
  invariant(id);
  const [client, setClient] = useState<IClientDetailedV1Response>();

  const navigate = useNavigate();
  const { user } = useUserContext();

  useEffect(() => {
    const fetchData = async () => {
      if (id) {
        try {
          const result = await getClient(id);
          setClient(result);
        } catch (err) {
          console.error(err);
        }
      }
    };

    fetchData();
  }, [id]);

  const authorizedToModify = [
    UserRole.COMPANY_MANAGER,
    UserRole.CLIENT_MANAGER,
  ];
  const authorizedToDelete = [UserRole.COMPANY_MANAGER];

  const buttons = (
    <>
      {user && user.role && authorizedToModify.includes(user.role) && (
        <ModifyButton
          className={styles.modifyButton}
          onClick={() => navigate(generatePath(Routes.ClientModify, { id }))}
        >
          {t('common.modify')}
        </ModifyButton>
      )}
      {user && user.role && authorizedToDelete.includes(user.role) && (
        <DeleteButton
          className={styles.modifyButton}
          onClick={async () => {
            try {
              if (window.confirm(t('client.delete.confirm'))) {
                await deleteClient(String(id));
                redirectWithSuccess('/clients', 'client.delete.success');
              }
            } catch (e) {
              redirectWithError('/clients', 'client.delete.error');
            }
          }}
        >
          {t('common.delete')}
        </DeleteButton>
      )}
    </>
  );

  return (
    <ConnectedContainer>
      <BasePage className={styles.container} footer={buttons}>
        <div>
          <button onClick={() => navigate(-1)} className={styles.returnButton}>
            &lt; {t('common.return')}
          </button>

          {client ? (
            <>
              <SectionTitle text={client.name} />
              <div className={styles.flex}>
                <div className={styles.spacer} />
                <div className={styles.mainButton}>{buttons}</div>
              </div>

              <div className={styles.panelContainer}>
                <div>
                  <div className={styles.rowIconText}>
                    <Icon width={16} height={16}>
                      chart-pie
                    </Icon>
                    <div className={styles.details}>{client.company.name}</div>
                  </div>

                  {client.clientManagers.length > 0 && (
                    <div
                      className={styles.rowIconText}
                      title={t('client.view.managers')}
                    >
                      <Icon width={16} height={16}>
                        account
                      </Icon>
                      <div className={styles.details}>
                        {client.clientManagers.map((manager, index) => (
                          <button
                            key={index}
                            className={styles.link}
                            type="button"
                            onClick={() =>
                              navigate(
                                generatePath(Routes.UserView, {
                                  id: manager.id,
                                }),
                              )
                            }
                          >
                            {`${manager.firstName} ${manager.lastName}`}
                          </button>
                        ))}
                      </div>
                    </div>
                  )}

                  {client.clientObservers.length > 0 && (
                    <div
                      className={styles.rowIconText}
                      title={t('client.view.observers')}
                    >
                      <Icon width={16} height={16}>
                        account
                      </Icon>
                      <div className={styles.details}>
                        {client.clientObservers.map((observer, index) => (
                          <button
                            key={index}
                            className={styles.link}
                            type="button"
                            onClick={() =>
                              navigate(
                                generatePath(Routes.UserView, {
                                  id: observer.id,
                                }),
                              )
                            }
                          >
                            {`${observer.firstName} ${observer.lastName}`}
                          </button>
                        ))}
                      </div>
                    </div>
                  )}

                  <div className={styles.rowIconText}>
                    <Icon width={16} height={16}>
                      clock
                    </Icon>
                    <div className={styles.details}>
                      {`${t('common.created')} ${formatDateLLL(
                        client.creationTime,
                      )} \n${t('common.updated')} ${formatDateLLL(
                        client.updateTime,
                      )}`}
                    </div>
                  </div>

                  <div className={styles.rowIconText}>
                    <div className={styles.details}>
                      {t(
                        client.customAlertsEnabled
                          ? 'client.view.customAlerts.enabled'
                          : 'client.view.customAlerts.disabled',
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </>
          ) : (
            <Loader />
          )}
        </div>
      </BasePage>
    </ConnectedContainer>
  );
};

export default ClientView;
