export enum DeviceTypeNetwork {
  SIGFOX = 'sigfox',
  EMAIL = 'email',
  CLEODE = 'cleode',
  ARTS_TELIT = 'arts_telit',
  MQTT = 'MQTT',
}

export enum DeviceTypes {
  Telit = 'telit',
  Zbms = 'zbms',
}

export enum ClientName {
  Arts = 'arts',
  Unknown = 'unknown',
}

export const MapDeviceToClientName: Record<DeviceTypes, ClientName> = {
  [DeviceTypes.Telit]: ClientName.Arts,
  [DeviceTypes.Zbms]: ClientName.Arts,
};
