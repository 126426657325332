import React from 'react';
import styles from './index.module.scss';
import classNames from 'classnames';
import { useNavigate } from 'react-router';

type LinkProps = {
  children: React.ReactNode;
  to: string;
  className?: string;
};

const Link = ({ children, to, className }: LinkProps) => {
  const navigate = useNavigate();

  return (
    <button
      className={classNames(styles.button, className)}
      type="button"
      onClick={() => navigate(to)}
    >
      {children}
    </button>
  );
};

export default Link;
