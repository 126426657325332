import React, { useState, useEffect } from 'react';
import { Field, Formik } from 'formik';
import { InputText } from '../../../../ui/form/inputText';

import { useTranslation } from 'react-i18next';
import { userAddValidation } from './service';
import { Select, StandaloneSelect } from '../../../../ui/form/select';
import { UserRole } from 'common-active-invest-supervision/dist/src/business/user/types';
import { InputCheckbox } from '../../../../ui/form/inputCheckbox/inputCheckbox';
import styles from './index.module.scss';
import { post, updateUser } from '../../services/api';
import classNames from 'classnames';
import { SectionTitle } from 'ui/typo/section-title';
import { ICompanyV1Response } from 'common-active-invest-supervision/dist/src/business/company/api/v1';
import { listCompanies } from 'business/company/services/api';
import { Modal } from 'ui/modal';
import FormCompanyAdd from 'business/company/pages/add/form';
import { IClientV1Response } from 'common-active-invest-supervision/dist/src/business/client/api/v1';
import { listClients } from 'business/client/services/api';
import FormClientAdd from 'business/client/pages/add/form';
import { useUserContext } from 'business/user/states';
import { mapUserRights } from '../modify';
import { Loader } from 'ui/loader';
import { PASSWORD_IS_TOO_WEAK } from 'common-active-invest-supervision/dist/src/technical/validation/rules';
import { IUserV1Response } from 'common-active-invest-supervision/dist/src/business/user/api/v1';
import { redirectWithSuccess } from 'technical/flash-message';
import { Button } from 'ui/button/button';
import { authorizedToAdd as authorizedToAddCompany } from 'business/company/pages/list/index';
import { authorizedToAdd as authorizedToAddClient } from 'business/client/pages/list/index';
import axios from 'axios';

export type UserAddValues = {
  firstName: string;
  lastName: string;
  login?: string;
  role: string;
  email: string;
  phone: string;
  password?: string;
  confirmPassword?: string;
  isActive: boolean;
  companyId: string;
  clientId: string;
};

const FormUserAdd: React.FC<{
  onAdded?: (createdUser: IUserV1Response) => void;
  initialData?: UserAddValues;
  modifyId?: string;
  isInsideModal?: boolean;
}> = props => {
  const { t } = useTranslation();
  const [loading, setLoading] = useState<boolean>(false);
  const [isPasswordTooWeak, setIsPasswordTooWeak] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);
  const [companies, setCompanies] = useState<ICompanyV1Response[]>([]);
  const [modalCompanyOpen, setModalCompanyOpen] = useState<boolean>(false);
  const [clients, setClients] = useState<IClientV1Response[]>([]);
  const [modalClientOpen, setModalClientOpen] = useState<boolean>(false);
  const [isFetchingCompanies, setIsFetchingCompanies] = useState<boolean>(
    false,
  );
  const [isFetchingClients, setIsFetchingClients] = useState<boolean>(false);
  const { user } = useUserContext();
  const availableRoles = user ? mapUserRights[user.role] : [];

  useEffect(() => {
    async function getClients() {
      try {
        setIsFetchingClients(true);
        const { items } = await listClients({ page: 1, limit: 100 });
        setClients(items);
      } catch (err) {
        console.error(err);
      } finally {
        setIsFetchingClients(false);
      }
    }

    async function getCompanies() {
      try {
        setIsFetchingCompanies(true);
        const { items } = await listCompanies({ page: 1, limit: 100 });
        setCompanies(items);
      } catch (err) {
        console.error(err);
      } finally {
        setIsFetchingCompanies(false);
      }
    }

    Promise.all([getCompanies(), getClients()]).catch(err => {
      console.error(err);
    });
  }, []);

  return (
    <>
      <SectionTitle
        text={props.modifyId ? t('user.modify.title') : t('user.add.title')}
      />

      {isFetchingCompanies || isFetchingClients ? (
        <Loader />
      ) : (
        <>
          <Formik<UserAddValues>
            initialValues={
              props.initialData
                ? props.initialData
                : {
                    firstName: '',
                    lastName: '',
                    login: '',
                    role: '',
                    email: '',
                    phone: '',
                    password: '',
                    confirmPassword: '',
                    isActive: true,
                    companyId: '',
                    clientId: '',
                  }
            }
            onSubmit={async (values: UserAddValues) => {
              setLoading(true);
              setIsPasswordTooWeak(false);
              setError(null);
              try {
                const valuesForApi = { ...values };
                delete valuesForApi.confirmPassword;

                if (props.modifyId) {
                  delete valuesForApi.login;
                  await updateUser(props.modifyId, valuesForApi);
                  redirectWithSuccess('/users', 'user.modify.form.success');
                } else {
                  const createdUser = await post(valuesForApi);
                  props.onAdded
                    ? props.onAdded(createdUser)
                    : redirectWithSuccess('/users', 'user.add.form.success');
                }
              } catch (err) {
                setError(t('user.add.form.error'));
                if (axios.isAxiosError(err)) {
                  // Necessary any for typing purpose
                  const data: any = err.response?.data;
                  if (err.status === 400) {
                    if (
                      data?.errors?.password?.includes(PASSWORD_IS_TOO_WEAK)
                    ) {
                      setIsPasswordTooWeak(true);
                    } else {
                      setError(t('user.add.form.loginError'));
                    }
                  }

                  if (err.status === 409) {
                    setError(t('user.add.form.alreadyInUseError'));
                  }
                }
              } finally {
                setLoading(false);
              }
            }}
            validate={values => userAddValidation(values, props.modifyId)}
          >
            {({ values, setFieldValue, submitForm }) => {
              return (
                <>
                  <Field
                    name="firstName"
                    placeholder={t('user.add.form.firstName.placeholder')}
                    component={InputText}
                    className={styles.inputText}
                  />
                  <Field
                    name="lastName"
                    placeholder={t('user.add.form.lastName.placeholder')}
                    component={InputText}
                    className={styles.inputText}
                  />
                  <Field
                    name="login"
                    placeholder={t('user.add.form.login.placeholder')}
                    component={InputText}
                    className={styles.inputText}
                    disabled={props.modifyId}
                  />
                  <Field
                    name="role"
                    placeholder={t('user.add.form.role.placeholder')}
                    component={Select}
                    options={
                      props.isInsideModal || props.modifyId
                        ? availableRoles.map(role => ({
                            value: role,
                            label: t(`user.add.form.role.options.${role}`),
                          }))
                        : availableRoles
                            .filter(role => role !== UserRole.MAINTAINER)
                            .map(role => ({
                              value: role,
                              label: t(`user.add.form.role.options.${role}`),
                            }))
                    }
                    className={styles.inputText}
                  />
                  {!props.isInsideModal && !props.modifyId && (
                    <p className={styles.warning}>
                      {t(`user.add.form.additional.maintainer`)}
                    </p>
                  )}
                  {[
                    UserRole.COMPANY_MANAGER,
                    UserRole.COMPANY_OBSERVER,
                  ].includes(values.role as UserRole) && (
                    <div className={styles.multipleOnLine}>
                      <div
                        className={classNames(
                          styles.canAddRow,
                          styles.elementOfLine,
                        )}
                      >
                        <StandaloneSelect
                          className={styles.inputText}
                          placeholder={t(`user.add.form.companyId.placeholder`)}
                          onChange={option => {
                            const stringValue = option && option.value;
                            setFieldValue('companyId', stringValue);
                            setFieldValue('clientId', '');
                          }}
                          options={companies.map(company => ({
                            value: company.id,
                            label: company.name,
                          }))}
                          value={
                            companies
                              .map(company => ({
                                value: company.id,
                                label: company.name,
                              }))
                              .find(
                                option => option.value === values.companyId,
                              ) || null
                          }
                        />
                        {!props.isInsideModal &&
                          user &&
                          authorizedToAddCompany.includes(user.role) && (
                            <button
                              className={styles.addButton}
                              onClick={e => {
                                e.preventDefault();
                                setModalCompanyOpen(true);
                              }}
                            >
                              +
                            </button>
                          )}
                      </div>
                    </div>
                  )}
                  {[UserRole.CLIENT_MANAGER, UserRole.CLIENT_OBSERVER].includes(
                    values.role as UserRole,
                  ) && (
                    <div className={styles.multipleOnLine}>
                      <div
                        className={classNames(
                          styles.canAddRow,
                          styles.elementOfLine,
                        )}
                      >
                        <StandaloneSelect
                          className={styles.inputText}
                          placeholder={t(`user.add.form.clientId.placeholder`)}
                          onChange={option => {
                            const stringValue = option && option.value;
                            setFieldValue('clientId', stringValue);
                            setFieldValue('companyId', '');
                          }}
                          options={clients.map(client => ({
                            value: client.id,
                            label: client.name,
                          }))}
                          value={
                            clients
                              .map(client => ({
                                value: client.id,
                                label: client.name,
                              }))
                              .find(
                                option => option.value === values.clientId,
                              ) || null
                          }
                        />
                        {!props.isInsideModal &&
                          user &&
                          authorizedToAddClient.includes(user.role) && (
                            <button
                              className={styles.addButton}
                              onClick={e => {
                                e.preventDefault();
                                setModalClientOpen(true);
                              }}
                            >
                              +
                            </button>
                          )}
                      </div>
                    </div>
                  )}
                  <Field
                    name="email"
                    placeholder={t('user.add.form.email.placeholder')}
                    component={InputText}
                    className={styles.inputText}
                  />
                  <Field
                    name="phone"
                    placeholder={t('user.add.form.phone.placeholder')}
                    component={InputText}
                    className={styles.inputText}
                  />
                  {props.modifyId ? null : (
                    <>
                      <Field
                        name="password"
                        type="password"
                        placeholder={t('user.add.form.password.placeholder')}
                        component={InputText}
                        autoComplete="new-password"
                        className={styles.inputText}
                      />
                      <p
                        className={
                          isPasswordTooWeak
                            ? styles.passwordInfoHighlighted
                            : styles.passwordInfo
                        }
                      >
                        {t('user.add.form.password.info')}
                      </p>
                      <Field
                        type="password"
                        name="confirmPassword"
                        placeholder={t(
                          'user.add.form.confirmPassword.placeholder',
                        )}
                        component={InputText}
                        autoComplete="new-password"
                        className={styles.inputText}
                      />
                    </>
                  )}
                  <Field
                    name="isActive"
                    placeholder={t('user.add.form.isActive.placeholder')}
                    component={InputCheckbox}
                    className={styles.inputText}
                    defaultChecked={values.isActive}
                  />
                  <div className={styles.actions}>
                    <Button
                      disabled={loading}
                      type="submit"
                      onClick={submitForm}
                    >
                      {props.modifyId
                        ? t('user.modify.form.submit')
                        : t('user.add.form.submit')}
                    </Button>
                  </div>
                  {error !== null && (
                    <div className={styles.errors}>{error}</div>
                  )}
                </>
              );
            }}
          </Formik>
          <Modal
            isOpen={modalCompanyOpen}
            onRequestClose={() => setModalCompanyOpen(false)}
          >
            <FormCompanyAdd
              onAdded={createdCompany => {
                setModalCompanyOpen(false);
                setCompanies([...companies, createdCompany]);
              }}
            />
          </Modal>
          <Modal
            isOpen={modalClientOpen}
            onRequestClose={() => setModalClientOpen(false)}
          >
            <FormClientAdd
              onAdded={createdClient => {
                setModalClientOpen(false);
                setClients([...clients, createdClient]);
              }}
            />
          </Modal>
        </>
      )}
    </>
  );
};

export default FormUserAdd;
