import { Routes } from 'bootstrap/router';
import { formatAnomaly } from 'business/alert/services';
import { listAlerts } from 'business/alert/services/api';
import { ConnectedContainer } from 'business/user/containers/connectedOrNotContainers';
import { useUserContext } from 'business/user/states';
import { IAlertDetailedV1Response } from 'common-active-invest-supervision/dist/src/business/alert/api/v1';
import { IUserDetailedV1Response } from 'common-active-invest-supervision/dist/src/business/user/api/v1';
import { UserRole } from 'common-active-invest-supervision/dist/src/business/user/types';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useMediaType } from 'technical/media/hooks';
import { AddButton } from 'ui/button/add-button';
import { Loader } from 'ui/loader';
import { NoResults } from 'ui/no-results';
import { Paginator } from 'ui/paginator';
import styles from 'ui/styles/list.module.scss';
import { Row } from 'ui/table';
import { SectionTitle } from 'ui/typo/section-title';
import BasePage from '../../../base-page';
import { Navigate, generatePath, useNavigate } from 'react-router';
import { InfoMessage } from 'ui/info-message';

export const isAllowed = (user: IUserDetailedV1Response) => {
  if (user.role === UserRole.COMPANY_MANAGER) {
    return true;
  }

  if (user.role === UserRole.CLIENT_MANAGER) {
    return user.client && user.client.customAlertsEnabled;
  }

  return false;
};

function AlertList() {
  const { t } = useTranslation();
  const [alerts, setAlerts] = useState<IAlertDetailedV1Response[]>([]);
  const [page, setPage] = useState<number>(1);
  const [totalPage, setTotalPage] = useState<number>(0);
  const navigate = useNavigate();
  const { isMobile } = useMediaType();
  const { user } = useUserContext();
  const [isFetching, setIsFetching] = useState<boolean>(false);

  useEffect(() => {
    async function getAlerts() {
      try {
        setIsFetching(true);
        const { items, metadata } = await listAlerts({ page });
        setAlerts(items);
        setTotalPage(metadata.pageCount);
      } catch (err) {
        console.error(err);
      } finally {
        setIsFetching(false);
      }
    }

    getAlerts();
  }, [page]);

  if (
    user &&
    user.role === UserRole.CLIENT_MANAGER &&
    user.client &&
    !user.client.customAlertsEnabled
  ) {
    return <Navigate replace to={Routes.Home} />;
  }

  const buttons =
    user && isAllowed(user) ? (
      <AddButton onClick={() => navigate(Routes.AlertAdd)}>
        {t('alert.list.add')}
      </AddButton>
    ) : null;

  return (
    <ConnectedContainer>
      <BasePage className={styles.container} footer={buttons}>
        <div>
          <SectionTitle text={t('alert.list.title')} />
          <div className={styles.actionContainer}>{!isMobile && buttons}</div>

          <InfoMessage />

          <Loader isLoading={isFetching} />

          <NoResults isVisible={!isFetching && !alerts.length} />

          {alerts.map(alert => (
            <Row
              key={alert.id}
              mobileColumns={[0]}
              rowElements={[
                {
                  text: alert.name,
                  subText: formatAnomaly(alert, t),
                  flex: 2,
                },
                {
                  subText: `${t('alert.list.devicesCount', {
                    count: alert.devices.length,
                  })}\n${t('alert.list.watchersCount', {
                    count: alert.watchers.length,
                  })}`,
                  flex: 1,
                },
              ]}
              onClick={() =>
                navigate(generatePath(Routes.AlertView, { id: alert.id }))
              }
            />
          ))}
          {totalPage > 0 && (
            <Paginator setPage={setPage} totalPage={totalPage} page={page} />
          )}
        </div>
      </BasePage>
    </ConnectedContainer>
  );
}

export default AlertList;
