import React from 'react';
import { ArtsEnergyLogo } from '../artsEnergy';
import styles from './index.module.scss';

interface Props {
  isHeader: boolean;
}

export const LogosGroup = ({ isHeader }: Props) => {
  if (!isHeader) {
    return (
      <div className={styles.logos}>
        <div className={styles.logosFirstRow}>
          <ArtsEnergyLogo width={180} />
        </div>
        <div className={styles.supervisionFont}>SUPERVISION</div>
      </div>
    );
  }

  return (
    <div className={styles.firstRow}>
      <ArtsEnergyLogo width={90} />
    </div>
  );
};
