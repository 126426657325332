import { axios } from '../../../technical/network';
import {
  IClientV1Response,
  IClientDetailedV1Response,
} from 'common-active-invest-supervision/dist/src/business/client/api/v1';
import { ClientAddValues } from '../pages/add';
import queryString from 'query-string';

export async function listClients(params: {
  page?: number;
  limit?: number;
}): Promise<{
  items: IClientDetailedV1Response[];
  metadata: {
    currentPage: number;
    pageCount: number;
  };
}> {
  const filter = {
    page: params.page ? params.page : 1,
    limit: params.limit ? params.limit : undefined,
  };
  const stringifiedQuery = queryString.stringify(filter);

  const response = await axios.get(
    `/clients${stringifiedQuery ? `?${stringifiedQuery}` : ''}`,
  );

  return response.data;
}

export async function getClient(
  id: string,
): Promise<IClientDetailedV1Response> {
  const response = await axios.get(`/client/${id}`);

  return response.data;
}

export async function updateClient(
  id: string,
  values: ClientAddValues,
): Promise<void> {
  const response = await axios.put(`/client/${id}`, values);
  if (response.status !== 200) {
    throw new Error();
  }
}

const CLIENT_POST_REQUEST_FAILED = 'CLIENT_POST_REQUEST_FAILED';
export async function post(values: ClientAddValues) {
  const response = await axios.post<IClientV1Response>('/client', values);
  if (response.status !== 201) {
    throw new Error(CLIENT_POST_REQUEST_FAILED);
  }

  return response.data;
}

const CLIENT_DELETE_REQUEST_FAILED = 'CLIENT_DELETE_REQUEST_FAILED';
export async function deleteClient(id: string): Promise<void> {
  const response = await axios.delete<void>(`/client/${id}`);
  if (response.status !== 204) {
    throw new Error(CLIENT_DELETE_REQUEST_FAILED);
  }
}
