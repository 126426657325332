import { FieldProps, ErrorMessage } from 'formik';
import * as React from 'react';
import styles from './index.module.scss';
import { ErrorField } from 'ui/form/error';
import classnames from 'classnames';

interface InputCheckboxInterface extends Omit<FieldProps, 'form'> {
  className: string;
  placeholder: string;
}

interface IStandaloneProps {
  label?: React.ReactNode;
  checked?: boolean;
  onChange?: (e: React.FormEvent<HTMLInputElement>) => void;
  className?: string;
}

export const InputCheckbox = ({
  field,
  className,
  placeholder,
  ...props
}: InputCheckboxInterface) => (
  <div className={classnames(styles.container, className)}>
    <input className={styles.checkbox} type="checkbox" {...field} {...props} />
    <label>{placeholder}</label>
    <ErrorMessage name={field.name} component={ErrorField} />
  </div>
);

export const StandaloneInputCheckbox = ({
  label,
  className,
  ...props
}: IStandaloneProps) => (
  <div className={classnames(styles.container, className)}>
    <input className={styles.checkbox} type="checkbox" {...props} />
    <label>{label}</label>
  </div>
);
