import validate from 'validate.js';
import { ClientAddValues } from '..';
import { REQUIRED } from '../../../../../technical/validation/rules';
import i18n from 'i18next';

export const clientAddValidation = ({ name }: ClientAddValues) => {
  const attributes = {
    name,
  };
  const constraints = {
    name: REQUIRED(i18n.t('client.add.form.name.required')),
  };

  return validate(attributes, constraints);
};
