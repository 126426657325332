import { axios } from 'technical/network';
import {
  ICompanyDetailedV1Response,
  ICompanyV1Response,
} from 'common-active-invest-supervision/dist/src/business/company/api/v1';
import queryString from 'query-string';
import { CompanyAddValues } from '../pages/add/form';

export async function getCompany(
  id: string,
): Promise<ICompanyDetailedV1Response> {
  const response = await axios.get(`/company/${id}`);

  return response.data;
}

export async function listCompanies(params: {
  page?: number;
  limit?: number;
}): Promise<{
  items: ICompanyDetailedV1Response[];
  metadata: {
    currentPage: number;
    pageCount: number;
  };
}> {
  const filter = {
    page: params.page ? params.page : 1,
    limit: params.limit ? params.limit : undefined,
  };
  const stringifiedQuery = queryString.stringify(filter);

  const response = await axios.get(
    `/companies${stringifiedQuery ? `?${stringifiedQuery}` : ''}`,
  );

  return response.data;
}

export async function updateCompany(
  id: string,
  values: CompanyAddValues,
): Promise<void> {
  const response = await axios.put(`/company/${id}`, values);
  if (response.status !== 201) {
    throw new Error();
  }
}

const COMPANY_DELETE_REQUEST_FAILED = 'COMPANY_DELETE_REQUEST_FAILED';
export async function deleteCompany(id: string): Promise<void> {
  const response = await axios.delete<void>(`/company/${id}`);
  if (response.status !== 204) {
    throw new Error(COMPANY_DELETE_REQUEST_FAILED);
  }
}

export async function post(values: CompanyAddValues) {
  const response = await axios.post<ICompanyV1Response>('/company', values);
  if (response.status !== 201) {
    throw new Error();
  }

  return response.data;
}
