import React, { useEffect, useState } from 'react';
import { Field, Formik } from 'formik';
import { InputText } from 'ui/form/inputText';
import { StandaloneInputCheckbox } from 'ui/form/inputCheckbox/inputCheckbox';
import { useTranslation } from 'react-i18next';
import { IDeviceDetailedV1Response } from 'common-active-invest-supervision/dist/src/business/device/api/v1';
import formStyles from 'ui/styles/add-form.module.scss';
import classNames from 'classnames';
import { SectionTitle } from 'ui/typo/section-title';
import { redirectWithSuccess } from 'technical/flash-message';
import { createArtifact, getDevices } from './service/api';
import { Button } from 'ui/button/button';
import styles from './form.module.scss';
import { getDeviceType } from 'business/device-type/services/api';
import { IDeviceTypeV1Response } from 'common-active-invest-supervision/dist/src/business/deviceType/api/v1';
import { Loader } from 'ui/loader';
import { valid } from 'semver';
import logger from 'technical/logger';
import moment from 'moment';

export type ArtifactValues = {
  version: string;
  file?: File;
};

const FormArtifactAdd: React.FC<{
  deviceTypeId: string;
  artifactType: 'firmware' | 'configuration';
  deviceId?: string;
  filters?: string;
  initialData?: ArtifactValues;
}> = ({ deviceTypeId, artifactType, deviceId, filters, initialData }) => {
  const { t } = useTranslation();
  const [devices, setDevices] = useState<IDeviceDetailedV1Response[]>([]);
  const [deviceType, setDeviceType] = useState<IDeviceTypeV1Response>();
  const [selectedDeviceIds, setSelectedDeviceIds] = useState<string[]>([]);
  const [selectedFile, setSelectedFile] = useState<File>();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string>();

  const isFirmware = artifactType === 'firmware';

  const handleSelectDevice = (id: string) => {
    if (selectedDeviceIds.includes(id)) {
      setSelectedDeviceIds(
        selectedDeviceIds.filter(deviceId => deviceId !== id),
      );

      return;
    }

    setSelectedDeviceIds([...selectedDeviceIds, id]);
  };

  const handleSelectFile = (event: React.ChangeEvent<HTMLInputElement>) => {
    const files = event.currentTarget.files;
    if (files && files.length > 0) {
      setSelectedFile(files[0]);
    }
  };

  const handleSelectAllDevices = () => {
    if (selectedDeviceIds.length === 0) {
      setSelectedDeviceIds(devices.map(device => device.id));

      return;
    }

    setSelectedDeviceIds([]);
  };

  const handleSubmit = async (values: ArtifactValues) => {
    if (!selectedFile) {
      setError(
        isFirmware
          ? t('artifact.firmware.add.form.file.error')
          : t('artifact.configuration.add.form.file.error'),
      );

      return;
    }

    if (!isFirmware && !moment(values.version, moment.ISO_8601).isValid()) {
      setError(t('artifact.configuration.add.form.version.error'));

      return;
    }

    if (isFirmware && !valid(values.version)) {
      setError(t('artifact.firmware.add.form.version.error'));

      return;
    }

    setLoading(true);
    setError(undefined);

    try {
      await createArtifact(
        selectedFile,
        JSON.stringify({
          deviceTypeId,
          artifactType,
          version: values.version,
          deviceIds: deviceId ? [deviceId] : selectedDeviceIds,
          isGlobalTargetVersion: isFirmware ? true : false,
        }),
      );
      if (deviceId) {
        redirectWithSuccess(
          `/device/view/${deviceId}?from-devices=true&${filters}`,
          `artifact.${artifactType}.add.form.success`,
        );
      } else {
        redirectWithSuccess(
          `/device-type/view/${deviceTypeId}`,
          `artifact.${artifactType}.add.form.success`,
        );
      }
    } catch (error) {
      if (error instanceof Error && error.message === 'already-exists') {
        setError(t(`artifact.${artifactType}.add.form.alreadyExists`));
      } else {
        setError(t(`artifact.${artifactType}.add.form.error`));
      }
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    setLoading(true);
    getDeviceType(deviceTypeId)
      .then(data => setDeviceType(data))
      .catch(() => setError(t(`artifact.${artifactType}.add.form.error`)))
      .finally(() => setLoading(false));
  }, [artifactType, deviceTypeId, t]);

  useEffect(() => {
    if (artifactType === 'configuration') {
      getDevices({ deviceTypeId })
        .then(response => setDevices(response.items))
        .catch(error => {
          logger.error(error);
          setError(t('artifact.deviceError'));
        });
    }
  }, [deviceTypeId, artifactType, t]);

  if (loading || !deviceType) {
    return <Loader />;
  }

  return (
    <>
      <SectionTitle
        text={t(`artifact.${artifactType}.add.title`, {
          name: deviceType.name,
        })}
      />
      {error && <div className={classNames(formStyles.errors)}>{error}</div>}
      <Formik
        initialValues={initialData ? initialData : { version: '' }}
        onSubmit={handleSubmit}
      >
        {({ submitForm }) => (
          <>
            <Field
              name="version"
              placeholder={t(
                `artifact.${artifactType}.add.form.version.placeholder`,
              )}
              component={InputText}
              className={formStyles.inputText}
            />
            <Field
              type="file"
              name="file"
              placeholder={t(
                `artifact.${artifactType}.add.form.file.placeholder`,
              )}
              onChange={handleSelectFile}
              component={InputText}
              className={formStyles.inputText}
            />
            {artifactType === 'configuration' && !deviceId && (
              <div className={styles.devices}>
                <div className={styles.label}>
                  {t(`artifact.${artifactType}.add.form.select`)}
                </div>
                <StandaloneInputCheckbox
                  label={t('artifact.select')}
                  checked={selectedDeviceIds.length === devices.length}
                  onChange={() => handleSelectAllDevices()}
                  className={formStyles.inputCheckbox}
                />
                {devices.map(element => (
                  <StandaloneInputCheckbox
                    key={element.id}
                    label={element.description}
                    checked={selectedDeviceIds.includes(element.id)}
                    onChange={() => handleSelectDevice(element.id)}
                    className={formStyles.inputCheckbox}
                  />
                ))}
              </div>
            )}
            <div className={formStyles.actions}>
              <Button disabled={loading} type="submit" onClick={submitForm}>
                {t(`artifact.${artifactType}.add.form.submit`)}
              </Button>
            </div>
          </>
        )}
      </Formik>
    </>
  );
};

export default FormArtifactAdd;
