import { IDeviceLocationV1Response } from 'common-active-invest-supervision/dist/src/business/device/api/v1';

export type DeviceLocationWithLatLng = Omit<
  IDeviceLocationV1Response,
  'position'
> & {
  lat: number;
  lng: number;
};

export type SiteLocation = { lat: number; lng: number };

export function isDeviceLocation(
  location: DeviceLocationWithLatLng | { lat: number; lng: number },
): location is DeviceLocationWithLatLng {
  if ((location as DeviceLocationWithLatLng).date) {
    return true;
  }

  return false;
}
