import { Routes } from 'bootstrap/router';
import queryString from 'query-string';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';

import styles from './index.module.scss';

export const BackToDeviceListButton = ({
  client,
  site,
  deviceType,
  comeFromListDevice,
}: {
  client: string | null;
  site: string | null;
  deviceType: string | null;
  comeFromListDevice: string | null;
}) => {
  const { t } = useTranslation();

  const navigate = useNavigate();

  return (
    <div className={styles.flex}>
      <button
        onClick={() => {
          const filters = queryString.stringify({
            client,
            site,
            deviceType,
          });
          navigate(
            comeFromListDevice
              ? `${Routes.DeviceList}?${filters}`
              : Routes.AlarmList,
          );
        }}
        className={styles.returnButton}
      >
        &lt; {t('common.return')}
      </button>
    </div>
  );
};
