import React from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';

import { Page } from 'ui/layout/page';
import { Spacer } from 'ui/layout/spacer';
import { Title } from 'ui/typo/title';

import styles from './index.module.scss';
import logger from 'technical/logger';

// TODO: function component
class ErrorBoundaryComponent extends React.Component<
  WithTranslation,
  { hasError: false }
> {
  constructor(props: WithTranslation) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError() {
    return { hasError: true };
  }

  componentDidCatch(error: Error) {
    logger.error(error);
  }

  render() {
    const { t } = this.props;
    if (this.state.hasError) {
      return (
        <Page>
          <Spacer pt={140} pb={20} mw={1200}>
            <Title
              text={t('error.rendering.fallback.title')}
              className={styles.title}
            />
          </Spacer>
        </Page>
      );
    }

    return this.props.children;
  }
}

const ErrorBoundary = withTranslation()(ErrorBoundaryComponent);

export default ErrorBoundary;
