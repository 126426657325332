export interface IUserV1CreateInput {
  firstName: string;
  lastName: string;
  birthday: string;
  login: string;
  password: string;
}

export interface IUserV1UpdateInput {
  firstName?: string;
  lastName?: string;
  birthday?: string;
}

export interface IUserV1Response {
  id: string;
  firstName: string;
  lastName: string;
  birthday: string;
  isActive: boolean;
}

export interface IUserV1RenewPassword {
  oldPassword: string;
  newPassword: string;
  confirmedPassword: string;
}

export enum UserErrorMessages {
  INVALID_CREDENTIALS = 'Invalid user credentials',
  COMPARE_PASSWORDS = 'New and confirmed password do not match',
}

// add extra jwt configuration options here
export enum UserTokenConfiguration {
  ISSUER = 'Express TS Skeleton',
  SUBJECT = 'Secured authentication',
}

export const UNAUTHORIZED_USER_MESSAGE_KEY = 'unauthorized_user';
export const FORBIDDEN_USER_MESSAGE_KEY = 'forbidden';
export const NOT_FOUND_USER_MESSAGE_KEY = 'user.not_found';
