import React from 'react';
import ReactModal from 'react-modal';
import styles from './index.module.scss';

export interface ModalProps {
  isOpen: boolean;
  onRequestClose?: () => void;
  children: React.ReactNode;
}

export const Modal: React.FC<ModalProps> = ({
  isOpen,
  children,
  onRequestClose,
  ...props
}) => {
  return (
    <ReactModal
      className={styles.modal}
      overlayClassName={styles.overlay}
      onRequestClose={onRequestClose}
      isOpen={isOpen}
      {...props}
    >
      <div className={styles.cross} onClick={onRequestClose}>
        &times;
      </div>
      {children}
    </ReactModal>
  );
};
