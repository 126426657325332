import { FieldProps, ErrorMessage, FormikErrors, FormikTouched } from 'formik';
import React from 'react';
import ReactSelect, {
  ActionMeta,
  GroupBase,
  OnChangeValue,
  StylesConfig,
  components,
} from 'react-select';
import styles from './index.module.scss';
import { ErrorField } from 'ui/form/error';
import classNames from 'classnames';
import { Indicator } from './indicator';

interface Iprops extends FieldProps {
  options: Option[];
  className?: string;
}

interface IStandaloneprops {
  options: Option[];
  className?: string;
  onChange?: ((
    value: OnChangeValue<Option, false>,
    actionMeta: ActionMeta<Option>,
  ) => void) &
    ((value: OnChangeValue<Option, false>, action: ActionMeta<Option>) => void);
  value?: OnChangeValue<Option, false>;
  placeholder?: string;
  defaultValue?: OnChangeValue<Option, false>;

  onBlur?: () => void;
  error?: string | FormikErrors<any>;
  touched?: boolean | FormikTouched<any>;
}

interface Option {
  label: string;
  value: string;
}

const DropdownIndicator = (props: any) => {
  return (
    components.DropdownIndicator && (
      <components.DropdownIndicator {...props}>
        <Indicator />
      </components.DropdownIndicator>
    )
  );
};

const reactSelectStyles: StylesConfig<Option, false, GroupBase<Option>> = {
  indicatorSeparator: () => ({}),
  control: provided => ({
    ...provided,
    borderRadius: '4px',
    border: '1px solid #ccc',
  }),
  valueContainer: provided => ({
    ...provided,
    padding: '10px 15px',
  }),
};

export const Select = ({
  className,
  field, // { name, value, onChange, onBlur }
  form: { touched, errors, setFieldValue, setFieldTouched }, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
  options,
  ...props
}: Iprops) => (
  <div className={classNames(styles.container, className)}>
    <StandaloneSelect
      options={options}
      onChange={option => {
        const stringValue = option && (option as Option).value;
        setFieldValue(field.name, stringValue);
      }}
      value={options.find(option => option.value === field.value) || null}
      onBlur={() => setFieldTouched(field.name, true)}
      error={errors[field.name] as any}
      touched={touched[field.name] as any}
      {...props}
    />
    <ErrorMessage name={field.name} component={ErrorField}></ErrorMessage>
  </div>
);

export const StandaloneSelect = ({ className, ...props }: IStandaloneprops) => (
  <ReactSelect
    styles={reactSelectStyles}
    components={{ DropdownIndicator }}
    className={classNames(styles.select, className)}
    {...props}
  />
);
