import { UserRole } from '../../business/user/types';

const validate = require('validate.js');

// Validation rules should all support custom messages injection at runtime
validate.options = {
  fullMessages: false,
};

const REQUIRED_MESSAGE_KEY = 'required';
const ENUM_MESSAGE_KEY = 'not_accepted_value';
export const ALPHABETICAL_MESSAGE_KEY = 'only_alphanumeric_accepted';
export const PASSWORD_IS_TOO_WEAK = 'password_is_too_weak';

// Default traduction keys for validation error messages
enum DefaultKeys {
  REQUIRED = 'required',
  ALPHABETICAL = 'not_alphabetical',
  LENGTH_RANGE_LONG = 'too_long',
  LENGTH_RANGE_SHORT = 'too_short',
  WRONG_EMAIL_FORMAT = 'wrong_email_format',
  WRONG_PASSWORD_FORMAT = 'wrong_password_format',
  WRONG_NUMBER_FORMAT = 'wrong_number_format',
  NOT_GREATER_THAN_OR_EQUAL_TO = 'not_gte',
  NOT_LESS_THAN_OR_EQUAL_TO = 'not_lte',
  NOT_CHECKED = 'not_checked',
  WRONG_BOOLEAN_FORMAT = 'wrong_boolean_format',
  WRONG_PHONE_FORMAT = 'wrong_phone_format',
  NOT_IN_ENUM = 'not_in_enum',
}

const ValidatorPatterns = {
  ALPHABETICAL_PATTERN: /^[^±!@£$%^&*+§¡€#¢§¶•ªº«\\/<>?:;|=€©\d]{0,250}$/,
  PHONE_NUMBER_PATTERN: /^(0|(\+33)|(0033)|(\+330)|(\+33\(0\)))[1-9]([0-9]{8})$/,
  PASSWORD_PATTERN: /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[a-zA-Z\d]{8,}$/,
};

export const REQUIRED = () => ({
  presence: { message: REQUIRED_MESSAGE_KEY, allowEmpty: false },
});

export const ALPHABETICAL = () => ({
  format: {
    message: ALPHABETICAL_MESSAGE_KEY,
    pattern: ValidatorPatterns.ALPHABETICAL_PATTERN,
    flags: 'i',
  },
});

export const INTEGER = (
  message: string = DefaultKeys.WRONG_NUMBER_FORMAT,
  notGreaterThanOrEqualToMessage: string = DefaultKeys.NOT_GREATER_THAN_OR_EQUAL_TO,
  notLessThanOrEqualToMessage: string = DefaultKeys.NOT_LESS_THAN_OR_EQUAL_TO,
) => ({
  numericality: {
    onlyInteger: true,
    notValid: message,
    notInteger: message,
    notGreaterThanOrEqualTo: notGreaterThanOrEqualToMessage,
    notLessThanOrEqualTo: notLessThanOrEqualToMessage,
    noStrings: true,
  },
});

export const NUMBER = (
  message: string = DefaultKeys.WRONG_NUMBER_FORMAT,
  notGreaterThanOrEqualToMessage: string = DefaultKeys.NOT_GREATER_THAN_OR_EQUAL_TO,
  notLessThanOrEqualToMessage: string = DefaultKeys.NOT_LESS_THAN_OR_EQUAL_TO,
) => ({
  numericality: {
    onlyInteger: false,
    notValid: message,
    notGreaterThanOrEqualTo: notGreaterThanOrEqualToMessage,
    notLessThanOrEqualTo: notLessThanOrEqualToMessage,
    noStrings: true,
  },
});

export const BOOLEAN = (
  message: string = DefaultKeys.WRONG_BOOLEAN_FORMAT,
) => ({
  inclusion: {
    message,
    within: [true, false],
  },
});

export const INTEGER_RANGE = (
  min: number,
  max: number,
  message: string = DefaultKeys.WRONG_NUMBER_FORMAT,
) => ({
  numericality: {
    ...INTEGER(message).numericality,
    message,
    greaterThanOrEqualTo: min,
    lessThanOrEqualTo: max,
  },
});

export const LENGTH_RANGE = (
  min: number,
  max: number,
  messageShort: string = DefaultKeys.LENGTH_RANGE_SHORT,
  messageLong: string = DefaultKeys.LENGTH_RANGE_LONG,
) => ({
  length: {
    minimum: min,
    maximum: max,
    tooShort: messageShort,
    tooLong: messageLong,
    getVariables: () => ({
      minimum: min,
      maximum: max,
    }),
  },
});

export const EMAIL = () => ({
  email: true,
});

export const ENUM = (enumObject: any) => ({
  inclusion: { message: ENUM_MESSAGE_KEY, within: Object.values(enumObject) },
});

export const INTEGER_STRING = (
  message: string = DefaultKeys.WRONG_NUMBER_FORMAT,
  notGreaterThanOrEqualToMessage: string = DefaultKeys.NOT_GREATER_THAN_OR_EQUAL_TO,
  notLessThanOrEqualToMessage: string = DefaultKeys.NOT_LESS_THAN_OR_EQUAL_TO,
) => ({
  numericality: {
    onlyInteger: true,
    notValid: message,
    notInteger: message,
    notGreaterThanOrEqualTo: notGreaterThanOrEqualToMessage,
    notLessThanOrEqualTo: notLessThanOrEqualToMessage,
    noStrings: false,
  },
});

export const CHECKED = (message: string = DefaultKeys.NOT_CHECKED) => ({
  inclusion: {
    message,
    within: [true],
  },
});

export const INTEGER_STRING_RANGE = (
  min: number,
  max: number,
  message: string = DefaultKeys.WRONG_NUMBER_FORMAT,
) => ({
  numericality: {
    ...INTEGER_STRING(message).numericality,
    greaterThanOrEqualTo: min,
    lessThanOrEqualTo: max,
  },
});

export const ZIP_CODE = (
  message: string = DefaultKeys.WRONG_NUMBER_FORMAT,
) => ({
  ...LENGTH_RANGE(5, 5),
  numericality: { notValid: message },
});

export const PHONE = (message: string = DefaultKeys.WRONG_PHONE_FORMAT) => ({
  format: {
    message,
    pattern: ValidatorPatterns.PHONE_NUMBER_PATTERN,
    flags: 'i',
  },
});

/**
 * Validates if password has 8 characeters, 1 CAP, 1 Lower case and 1 number
 */
validate.validators.password = (
  password: string,
  options: { [key: string]: string },
): string | void => {
  const passwordValidator = new RegExp(ValidatorPatterns.PASSWORD_PATTERN);
  if (!passwordValidator.test(password)) {
    return options.message;
  }
};

interface IRequiredDependingOnOptions {
  key: string;
}

validate.validators.requiredDependingOn = function(
  _value: unknown,
  options: IRequiredDependingOnOptions,
  key: string,
  attributes: any,
) {
  if (!attributes[key] && !attributes[options.key]) {
    return 'required';
  }
  return null;
};

validate.validators.optionalEmail = (value: string) => {
  if (value === '') {
    return null;
  }
  return validate.single(value, { email: true });
};

export interface IRequiredForRoleOptions {
  key: string;
  requiredRole: UserRole[];
}

validate.validators.requiredForRole = function(
  _value: unknown,
  options: IRequiredForRoleOptions,
  key: string,
  attributes: any,
) {
  if (
    !attributes[key] &&
    options.requiredRole.includes(attributes[options.key])
  ) {
    return 'required';
  }

  return null;
};

/**
 * Defines the PASSWORD validation rule, passes a { message } options object to the validator passwor
 */
export const PASSWORD = (
  message: string = DefaultKeys.WRONG_PASSWORD_FORMAT,
) => ({
  password: { message },
});

const LAT_LNG_FORMAT = /^(\-?\d+(\.\d+)?),\s*(\-?\d+(\.\d+)?)$/;

export const LOCATION = {
  format: {
    pattern: LAT_LNG_FORMAT,
    message: 'incorrect_format',
  },
};

export const DEVICE_LOCATION = {
  format: {
    pattern: '',
    message: 'incorrect_format',
  },
};
