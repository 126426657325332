export enum UserRole {
  GROUP_MANAGER = 'group_manager',
  COMPANY_MANAGER = 'company_manager',
  CLIENT_MANAGER = 'client_manager',
  MAINTAINER = 'maintainer',
  GROUP_OBSERVER = 'group_observer',
  COMPANY_OBSERVER = 'company_observer',
  CLIENT_OBSERVER = 'client_observer',
}

export enum UserLocale {
  FR = 'fr-fr',
  EN = 'en-gb',
}
