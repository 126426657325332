import React from 'react';
import DeviceStatusLine from './deviceStatusLine';
import styles from './index.module.scss';

const DeviceStatus: React.FC<{
  okCount: number;
  criticalCount: number;
  majorCount: number;
  minorCount: number;
  total: number;
  icon: string;
}> = ({ okCount, criticalCount, majorCount, minorCount, total, icon }) => {
  if (!total) {
    return null;
  }

  return (
    <div className={styles.container}>
      <DeviceStatusLine
        count={okCount}
        alertStyle={styles.okAlerts}
        total={total}
        icon={icon}
      />

      {minorCount > 0 && (
        <DeviceStatusLine
          count={minorCount}
          alertStyle={styles.minorAlerts}
          total={total}
          icon={icon}
        />
      )}

      {majorCount > 0 && (
        <DeviceStatusLine
          count={majorCount}
          alertStyle={styles.majorAlerts}
          total={total}
          icon={icon}
        />
      )}

      {criticalCount > 0 && (
        <DeviceStatusLine
          count={criticalCount}
          alertStyle={styles.criticalAlerts}
          total={total}
          icon={icon}
        />
      )}
    </div>
  );
};

export { DeviceStatus };
