import React from 'react';
import { useTranslation } from 'react-i18next';
import { Field, Form } from 'formik';
import { Select } from 'ui/form/select';
import styles from './index.module.scss';
import classNames from 'classnames';
import { ISiteV1Response } from 'common-active-invest-supervision/dist/src/business/site/api/v1';
import { IDeviceTypeV1Response } from 'common-active-invest-supervision/dist/src/business/deviceType/api/v1';
import { IClientV1Response } from 'common-active-invest-supervision/dist/src/business/client/api/v1';

interface Props {
  clients: IClientV1Response[];
  sites: ISiteV1Response[];
  deviceTypes: IDeviceTypeV1Response[];
}

function FilterForm({ clients, sites, deviceTypes }: Props) {
  const { t } = useTranslation();

  return (
    <Form className={styles.form}>
      <Field
        className={styles.select}
        name="client"
        component={Select}
        placeholder={t('device.list.client.placeholder')}
        options={[
          {
            label: t('device.list.client.all'),
            value: '',
          },
          ...clients.map(c => ({
            label: c.name,
            value: c.id,
          })),
        ]}
      />
      <Field
        className={classNames(styles.select, styles.site)}
        name="site"
        component={Select}
        placeholder={t('device.list.site.placeholder')}
        options={[
          {
            label: t('device.list.site.all'),
            value: '',
          },
          ...sites.map(s => ({
            label: s.name,
            value: s.id,
          })),
        ]}
      />
      <Field
        className={styles.select}
        name="deviceType"
        component={Select}
        placeholder={t('device.list.deviceType.placeholder')}
        options={[
          {
            label: t('device.list.deviceType.all'),
            value: '',
          },
          ...deviceTypes.map(dt => ({
            label: dt.name,
            value: dt.id,
          })),
        ]}
      />
    </Form>
  );
}

export default FilterForm;
