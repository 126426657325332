import React from 'react';
import styles from './index.module.scss';
import { Icon } from 'ui/icons';
import classNames from 'classnames';

const DeviceStatusLine: React.FC<{
  count: number;
  alertStyle: string;
  total: number;
  icon: string;
}> = ({ count, alertStyle, total, icon }) => {
  if (!total) {
    return null;
  }

  return (
    <div className={styles.containerLine}>
      <div className={classNames(styles.marker, alertStyle)} />
      <div className={styles.icon}>
        <Icon width={25} height={25}>
          {icon}
        </Icon>
      </div>
      <div className={styles.text}>
        <span>{count}</span>
        <span className={styles.slash}>/</span>
        <span>{total}</span>
      </div>
    </div>
  );
};

export default DeviceStatusLine;
