import React, { useEffect, useState } from 'react';
import BasePage from '../../../base-page';
import { useTranslation } from 'react-i18next';
import { ConnectedContainer } from 'business/user/containers/connectedOrNotContainers';
import styles from './index.module.scss';
import { SectionTitle } from 'ui/typo/section-title';

import { useNavigate, useParams, generatePath } from 'react-router';
import { getAlert, deleteAlert } from 'business/alert/services/api';
import { Icon } from 'ui/icons';
import { formatDateLLL } from 'technical/date';
import { useUserContext } from 'business/user/states';
import { IAlertDetailedV1Response } from 'common-active-invest-supervision/dist/src/business/alert/api/v1';
import { formatAnomalyView } from 'business/alert/services';
import { Loader } from 'ui/loader';
import {
  redirectWithSuccess,
  redirectWithError,
} from 'technical/flash-message';
import { DeleteButton } from 'ui/button/delete-button';
import { ModifyButton } from 'ui/button/modify-button';
import { SeverityBubble } from 'ui/severity-bubble';
import { isAllowed } from '../list';
import { Routes } from 'bootstrap/router';
import { invariant } from 'technical/router/query-params';

export default function AlertView() {
  const { t } = useTranslation();
  const { id } = useParams<{ id: string }>();
  invariant(id);
  const [alert, setAlert] = useState<IAlertDetailedV1Response | null>(null);

  const navigate = useNavigate();
  const { user } = useUserContext();

  useEffect(() => {
    const fetchData = async () => {
      if (id) {
        try {
          const result = await getAlert(id);
          setAlert(result);
        } catch (err) {
          console.error(err);
        }
      }
    };

    fetchData();
  }, [id]);

  const buttons = (
    <>
      {user && isAllowed(user) ? (
        <>
          <ModifyButton
            className={styles.modifyButton}
            onClick={() => navigate(generatePath(Routes.AlertModify, { id }))}
          >
            {t('common.modify')}
          </ModifyButton>
          <DeleteButton
            className={styles.modifyButton}
            onClick={async () => {
              try {
                if (window.confirm(t('alert.delete.confirm'))) {
                  await deleteAlert(String(id));
                  redirectWithSuccess('/alerts', 'alert.delete.success');
                }
              } catch (e) {
                redirectWithError('/alerts', 'alert.delete.error');
              }
            }}
          >
            {t('common.delete')}
          </DeleteButton>
        </>
      ) : null}
    </>
  );

  return (
    <ConnectedContainer>
      <BasePage className={styles.container} footer={buttons}>
        <div>
          <button onClick={() => navigate(-1)} className={styles.returnButton}>
            &lt; {t('common.return')}
          </button>

          {alert ? (
            <>
              <SectionTitle text={alert.name} />
              <div className={styles.flex}>
                <div className={styles.spacer} />
                <div className={styles.mainButton}>{buttons}</div>
              </div>

              <SeverityBubble
                severity={alert.severity}
                className={styles.bubble}
              />
              <span>{t(`alert.severity.${alert.severity}`)}</span>

              <h3 className={styles.mainInfo}>{formatAnomalyView(alert, t)}</h3>

              <div className={styles.rowIconText}>
                <Icon width={16} height={16}>
                  clock
                </Icon>
                <div className={styles.details}>
                  {`${t('alert.view.created', {
                    date: formatDateLLL(alert.creationTime),
                    name: `${alert.creator.firstName} ${alert.creator.lastName}`,
                  })} \n${t('common.updated')} ${formatDateLLL(
                    alert.updateTime,
                  )}`}
                </div>
              </div>

              <div className={styles.rowIconText}>
                <Icon width={16} height={16}>
                  settings
                </Icon>
                <div className={styles.details}>
                  {t('alert.maintainersAutomaticAlerts')}
                </div>
              </div>

              <div className={styles.list}>
                <p>{t('alert.view.watchers')}</p>
                <ul>
                  {alert.watchers
                    .filter(w => w.isActivated)
                    .map((watcher, index) => (
                      <li key={index}>{watcher.email || watcher.phone}</li>
                    ))}
                </ul>
              </div>
              <div className={styles.list}>
                <p>{t('alert.view.devices')}</p>
                <ul>
                  {alert.devices.map(device => (
                    <li key={device.id}>{device.description}</li>
                  ))}
                </ul>
              </div>
            </>
          ) : (
            <Loader />
          )}
        </div>
      </BasePage>
    </ConnectedContainer>
  );
}
