import React, { useState, useEffect } from 'react';
import BasePage from '../../../base-page';
import styles from 'ui/styles/list.module.scss';
import { Row } from 'ui/table';
import { SectionTitle } from 'ui/typo/section-title';
import { useTranslation } from 'react-i18next';

import { useMediaType } from 'technical/media/hooks';
import { Modal } from 'ui/modal';
import { Routes } from 'bootstrap/router';
import { ConnectedContainer } from 'business/user/containers/connectedOrNotContainers';

import { useUserContext } from 'business/user/states';
import { UserRole } from 'common-active-invest-supervision/dist/src/business/user/types';
import { Paginator } from 'ui/paginator';
import { ICompanyDetailedV1Response } from 'common-active-invest-supervision/dist/src/business/company/api/v1';
import { listCompanies } from 'business/company/services/api';
import { formatDateLLL } from 'technical/date';
import { Loader } from 'ui/loader';
import { NoResults } from 'ui/no-results';
import { Button } from 'ui/button/button';
import { AddButton } from 'ui/button/add-button';
import { Navigate, useNavigate } from 'react-router';
import { InfoMessage } from 'ui/info-message';

export const authorizedToAdd = [UserRole.GROUP_MANAGER];

const CompanyList: React.FC = () => {
  const { t } = useTranslation();
  const [companies, setCompanies] = useState<ICompanyDetailedV1Response[]>([]);
  const [page, setPage] = useState<number>(1);
  const [totalPage, setTotalPage] = useState<number>(0);
  const [searchModalOpen, setSearchModalOpen] = useState<boolean>(false);
  const navigate = useNavigate();
  const { isMobile } = useMediaType();
  const { user } = useUserContext();
  const [isFetching, setIsFetching] = useState<boolean>(false);

  useEffect(() => {
    async function getCompanies() {
      try {
        setIsFetching(true);
        const { items, metadata } = await listCompanies({ page });
        setCompanies(items);
        setTotalPage(metadata.pageCount);
      } catch (err) {
        console.error(err);
      } finally {
        setIsFetching(false);
      }
    }

    getCompanies();
  }, [page]);

  const authorizedUserGroups = [
    UserRole.GROUP_MANAGER,
    UserRole.GROUP_OBSERVER,
    UserRole.COMPANY_MANAGER,
    UserRole.COMPANY_OBSERVER,
  ];
  if (user && !authorizedUserGroups.includes(user.role)) {
    return <Navigate replace to={Routes.Home} />;
  }

  const buttons =
    user && authorizedToAdd.includes(user.role) ? (
      <>
        <AddButton onClick={() => navigate(Routes.CompanyAdd)}>
          {t('company.list.add')}
        </AddButton>
      </>
    ) : null;

  return (
    <ConnectedContainer>
      <BasePage className={styles.container} footer={buttons}>
        <div>
          <SectionTitle text={t('company.list.title')} />
          <div className={styles.actionContainer}>{!isMobile && buttons}</div>

          <InfoMessage />

          <Loader isLoading={isFetching} />

          <NoResults isVisible={!isFetching && !companies.length} />

          {companies.map(company => (
            <Row
              key={company.id}
              mobileColumns={[0]}
              rowElements={[
                {
                  text: company.name,
                  flex: 1,
                },
                {
                  subText: company.companyManagers
                    .map(manager => `${manager.firstName} ${manager.lastName}`)
                    .join('\n'),
                  flex: 2,
                },
                {
                  subText: `${t('company.list.created')} ${formatDateLLL(
                    company.creationTime,
                  )} \n${t('company.list.updated')} ${formatDateLLL(
                    company.updateTime,
                  )}`,
                  flex: 3,
                },
              ]}
              onClick={() => navigate(`/company/view/${company.id}`)}
            />
          ))}
          {totalPage > 0 && (
            <Paginator setPage={setPage} totalPage={totalPage} page={page} />
          )}
        </div>
        <Modal
          isOpen={searchModalOpen}
          onRequestClose={() => setSearchModalOpen(false)}
        >
          <Button
            className={styles.buttonModal}
            onClick={() => navigate(Routes.CompanyAdd)}
          >
            {t('company.list.add')}
          </Button>
        </Modal>
      </BasePage>
    </ConnectedContainer>
  );
};

export default CompanyList;
