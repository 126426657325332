import classnames from 'classnames';
import { IUserDetailedV1Response } from 'common-active-invest-supervision/dist/src/business/user/api/v1';
import { UserRole } from 'common-active-invest-supervision/dist/src/business/user/types';
import React, { HTMLAttributes, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useMediaType } from 'technical/media/hooks';
import { Icon } from 'ui/icons';
import LocaleButton from 'ui/layout/header/locale-button';
import { ArtsEnergyLogo } from 'ui/logos';
import styles from './index.module.scss';
import { useNavigate } from 'react-router';

interface IProps extends HTMLAttributes<HTMLHeadElement> {
  user: IUserDetailedV1Response;
  currentRoute?: string;
  isConnected?: boolean;
  logout?: () => void;
  goToHome?: () => void;
}

const headerLinks: {
  stateKey: 'devices' | 'alarms' | 'manageMenu';
  route?: string;
  highlightedRoutes: RegExp;
  translateKey: string;
  hoverContent: {
    translateKey: string;
    route: string;
    accessibleCondition: (user: IUserDetailedV1Response) => boolean;
  }[];
}[] = [
  {
    stateKey: 'devices',
    route: '/devices',
    highlightedRoutes: /^\/device(s$|\/)/,
    translateKey: 'header.devices',
    hoverContent: [],
  },
  {
    stateKey: 'alarms',
    route: '/alarms',
    highlightedRoutes: /^\/alarms/,
    translateKey: 'header.alarms',
    hoverContent: [],
  },
  {
    stateKey: 'manageMenu',
    highlightedRoutes: /^\/(alert|client|compan(y|ies)|device-type|site|user)/,
    translateKey: 'header.manageMenu',
    hoverContent: [
      {
        translateKey: 'header.users',
        route: '/users',
        accessibleCondition: user =>
          [
            UserRole.GROUP_MANAGER,
            UserRole.GROUP_OBSERVER,
            UserRole.COMPANY_MANAGER,
            UserRole.COMPANY_OBSERVER,
            UserRole.CLIENT_MANAGER,
            UserRole.CLIENT_OBSERVER,
          ].includes(user.role),
      },
      {
        translateKey: 'header.companies',
        route: '/companies',
        accessibleCondition: user =>
          [
            UserRole.GROUP_MANAGER,
            UserRole.GROUP_OBSERVER,
            UserRole.COMPANY_MANAGER,
            UserRole.COMPANY_OBSERVER,
          ].includes(user.role),
      },
      {
        translateKey: 'header.clients',
        route: '/clients',
        accessibleCondition: user =>
          [
            UserRole.GROUP_MANAGER,
            UserRole.GROUP_OBSERVER,
            UserRole.COMPANY_MANAGER,
            UserRole.COMPANY_OBSERVER,
            UserRole.CLIENT_MANAGER,
            UserRole.CLIENT_OBSERVER,
          ].includes(user.role),
      },
      {
        translateKey: 'header.sites',
        route: '/sites',
        accessibleCondition: user =>
          [
            UserRole.GROUP_MANAGER,
            UserRole.GROUP_OBSERVER,
            UserRole.COMPANY_MANAGER,
            UserRole.COMPANY_OBSERVER,
            UserRole.CLIENT_MANAGER,
            UserRole.CLIENT_OBSERVER,
          ].includes(user.role),
      },
      {
        translateKey: 'header.deviceTypes',
        route: '/device-types',
        accessibleCondition: user =>
          [
            UserRole.GROUP_MANAGER,
            UserRole.GROUP_OBSERVER,
            UserRole.COMPANY_MANAGER,
            UserRole.COMPANY_OBSERVER,
          ].includes(user.role),
      },
      {
        translateKey: 'header.alerts',
        route: '/alerts',
        accessibleCondition: user => {
          if (user.role === UserRole.CLIENT_MANAGER) {
            return !!user.client && user.client.customAlertsEnabled;
          }

          return true;
        },
      },
    ],
  },
];

const Header: React.FC<IProps> = ({
  user,
  currentRoute = '',
  isConnected = false,
  goToHome,
  logout,
}) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [mobileMenuOpened, setMobileMenuOpened] = useState<boolean>(false);
  const [subRouteOpened, setSubRouteOpened] = useState<{
    devices: boolean;
    alarms: boolean;
    manageMenu: boolean;
  }>({
    devices: false,
    alarms: false,
    manageMenu: false,
  });

  const { isUnderDesktop } = useMediaType();

  return (
    <div className={styles.headerContainer}>
      <div className={styles.logoContainer} onClick={goToHome}>
        <ArtsEnergyLogo width={104} />
      </div>
      {headerLinks.map((headerLink, index) => {
        const accessibleLinks = headerLink.hoverContent.filter(link =>
          link.accessibleCondition(user),
        );

        return (
          <div
            key={index}
            className={classnames(
              headerLink.highlightedRoutes.test(currentRoute)
                ? styles.headerLinkContainerSelected
                : null,
              styles.headerLinkContainer,
              mobileMenuOpened ? styles.mobileHeaderLink : null,
            )}
            onClick={
              currentRoute === headerLink.route ||
              (isUnderDesktop && accessibleLinks.length > 0)
                ? () => {
                    setSubRouteOpened({
                      ...subRouteOpened,
                      [headerLink.stateKey]: !subRouteOpened[
                        headerLink.stateKey
                      ],
                    });
                  }
                : () =>
                    headerLink.route ? navigate(headerLink.route) : undefined
            }
          >
            <div className={styles.headerLink}>
              {t(headerLink.translateKey)}
            </div>
            {accessibleLinks.length > 0 &&
              ((isUnderDesktop && subRouteOpened[headerLink.stateKey]) ||
                !isUnderDesktop) && (
                <div className={styles.dropdownContent}>
                  {accessibleLinks.map((content, index) => (
                    <div
                      key={index}
                      className={styles.subRouteLink}
                      onClick={event => {
                        event.stopPropagation();
                        if (currentRoute !== content.route) {
                          navigate(content.route);
                        }
                      }}
                    >
                      {t(content.translateKey)}
                    </div>
                  ))}
                </div>
              )}
          </div>
        );
      })}

      <div className={styles.spacer} />

      {isConnected && (
        <>
          <div
            className={classnames(
              styles.userMenu,
              mobileMenuOpened ? styles.mobileHeaderLink : null,
            )}
          >
            {user.client && user.client.company.email ? (
              <section>
                <Icon width={24} height={24}>
                  email
                </Icon>
                <a
                  href={`mailto:${user.client.company.email}`}
                  className={styles.emailLink}
                >
                  {t('user.contactUs')}
                </a>
              </section>
            ) : null}
            {user.client && user.client.company.phone ? (
              <section>
                <Icon width={24} height={24}>
                  phone
                </Icon>
                <span className={styles.phone}>
                  {user.client.company.phone}
                </span>
              </section>
            ) : null}

            <section>
              <span className={styles.headerLink}>
                <LocaleButton text="Fr" locale="fr-FR" />/
                <LocaleButton text="En" locale="en-GB" />
              </span>
            </section>

            <section>
              <Icon width={24} height={24}>
                account
              </Icon>
              <span className={classnames(styles.headerLink, styles.login)}>
                {user.login}
              </span>
            </section>

            <section>
              <button className={styles.logout} type="button" onClick={logout}>
                {t('user.logout')}
              </button>
            </section>
          </div>
        </>
      )}
      <div className={styles.menuIcon}>
        <div className={styles.spacer} />

        <div onClick={() => setMobileMenuOpened(!mobileMenuOpened)}>
          <Icon width={48} height={48}>
            menu
          </Icon>
        </div>
      </div>
    </div>
  );
};

export { Header };
