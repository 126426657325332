import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react';
import logger from 'technical/logger';
import {
  getUserMe,
  login as requestLogin,
  logout as requestLogout,
} from '../services/api';
import { LoginValues } from '../pages/login';
import {
  removeSession,
  saveSession,
  getSession,
} from '../services/sessionStore';
import { IUserDetailedV1Response } from 'common-active-invest-supervision/dist/src/business/user/api/v1';
import i18n from 'i18next';
import moment from 'moment';

export interface UserContextParams {
  isConnected: boolean;
  isLoading: boolean;
  logout: () => void;
  login: (values: LoginValues) => void;
  user: IUserDetailedV1Response | null;
  locale: string;
  setLocale: (l: string) => void;
}

export const UserContext = createContext<UserContextParams>({
  isConnected: false,
  isLoading: false,
  logout: () => {},
  login: () => {},
  user: null,
  locale: 'en',
  setLocale: () => {},
});

function useUserStateProvider() {
  const [bearer, setBearer] = useState<null | string>(getSession());
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [user, setUser] = useState<IUserDetailedV1Response | null>(null);
  const [locale, setLocale] = useState<string>('en');

  useEffect(() => {
    if (bearer) {
      setIsLoading(true);
      getUserMe(bearer)
        .then(newUser => {
          setUser(newUser.data);

          if (newUser.data.locale) {
            setLocale(newUser.data.locale);
          }
        })
        .catch(() => {
          setUser(null);
          // Error during user fetch: do nothing
        })
        .finally(() => setIsLoading(false));
    }
    // tips: explicit bearer as a dependency to refetch user when connecting
  }, [bearer]);

  useEffect(() => {
    i18n.changeLanguage(locale);
    moment.locale(locale);
  }, [locale]);

  const logout = useCallback(async () => {
    await requestLogout();
    setBearer(null);
    setUser(null);
    removeSession();
  }, [setBearer]);

  const login = useCallback(
    async (values: LoginValues) => {
      try {
        setIsLoading(true);
        const { sessionId, redirect } = await requestLogin(values);
        if (redirect) {
          window.location.assign(redirect);
        }
        if (sessionId) {
          setBearer(sessionId);
          saveSession(sessionId);
        }
      } catch (e) {
        setIsLoading(false);
        throw e;
      }
    },
    [setBearer],
  );

  return {
    bearer,
    isLoading,
    logout,
    login,
    user,
    locale,
    setLocale,
  };
}

export const UserProvider: React.FC = ({ children }) => {
  const {
    bearer,
    isLoading,
    logout,
    login,
    user,
    locale,
    setLocale,
  } = useUserStateProvider();
  logger.info('UserProvider', { isLoading });

  return (
    <UserContext.Provider
      value={{
        user,
        isLoading,
        logout,
        login,
        locale,
        setLocale,
        isConnected: !!bearer,
      }}
    >
      {children}
    </UserContext.Provider>
  );
};

export const useUserContext = () => useContext(UserContext);
