import { DeviceLocationWithLatLng, SiteLocation } from 'business/device/types';
import {
  IDeviceDetailedV1Response,
  IDeviceV1ResponseLight,
} from 'common-active-invest-supervision/dist/src/business/device/api/v1';

export function computeDeviceLocation(
  device: IDeviceDetailedV1Response | IDeviceV1ResponseLight,
): DeviceLocationWithLatLng | SiteLocation {
  const baseLocation = {
    lat: device.deviceLocation?.length
      ? device.deviceLocation[0].position.x
      : device.site.location.x,
    lng: device.deviceLocation?.length
      ? device.deviceLocation[0].position.y
      : device.site.location.y,
  };

  if (device.deviceLocation?.length) {
    return {
      ...device.deviceLocation[0],
      ...baseLocation,
    };
  }

  return baseLocation;
}
