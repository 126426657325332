import React, { useState, useEffect } from 'react';
import BasePage from '../../../base-page';
import styles from './index.module.scss';
import { Row } from 'ui/table';
import { SectionTitle } from 'ui/typo/section-title';
import { useTranslation } from 'react-i18next';
import { StandaloneSelect } from 'ui/form/select';
import { Icon } from 'ui/icons';

import { useMediaType } from 'technical/media/hooks';
import { Modal } from 'ui/modal';
import { Paginator } from 'ui/paginator';
import { ConnectedContainer } from 'business/user/containers/connectedOrNotContainers';
import { listAlarms, exportCsv } from 'business/alarm/services/api';
import { IAlarmDetailedV1Response } from 'common-active-invest-supervision/dist/src/business/alarm/api/v1';
import { listClients } from 'business/client/services/api';
import { IClientDetailedV1Response } from 'common-active-invest-supervision/dist/src/business/client/api/v1';
import { listSites } from 'business/site/services/api';
import { ISiteDetailV1Response } from 'common-active-invest-supervision/dist/src/business/site/api/v1';
import { listDeviceTypes } from 'business/device-type/services/api';
import { IDeviceTypeV1Response } from 'common-active-invest-supervision/dist/src/business/deviceType/api/v1';
import { ALARM_STATUS } from 'common-active-invest-supervision/dist/src/business/alarm/types';
import { formatDateLLL, superiorTime } from 'technical/date';
import { formatAnomaly } from 'business/alert/services';
import { Loader } from 'ui/loader';
import { NoResults } from 'ui/no-results';
import { Button } from 'ui/button/button';
import { Export } from 'ui/icons/resources/export';
import { Separator } from '../../../../ui/layout/separator';
import { generatePath, useNavigate } from 'react-router';
import { Routes } from 'bootstrap/router';

const AlarmList: React.FC = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [alarms, setAlarms] = useState<IAlarmDetailedV1Response[]>([]);
  const [page, setPage] = useState<number>(1);
  const [totalPage, setTotalPage] = useState<number>(0);
  const [searchModalOpen, setSearchModalOpen] = useState<boolean>(false);
  const [searchParameters, setSearchParameters] = useState<{
    clientId: string;
    siteId: string;
    deviceTypeId: string;
    status: string;
  }>({
    clientId: '',
    siteId: '',
    deviceTypeId: '',
    status: ALARM_STATUS.OPEN_NOT_ACKNOWLEDGED,
  });
  const [isFetchingFilters, setIsFetchingFilters] = useState<boolean>(false);
  const [isFetchingAlarms, setIsFetchingAlarms] = useState<boolean>(false);
  const [clients, setClients] = useState<IClientDetailedV1Response[]>([]);
  const [sites, setSites] = useState<ISiteDetailV1Response[]>([]);
  const [deviceTypes, setDeviceTypes] = useState<IDeviceTypeV1Response[]>([]);

  const { isMobile } = useMediaType();

  useEffect(() => {
    async function getClients() {
      try {
        const { items } = await listClients({ page: 1, limit: 100 });
        setClients(items);
      } catch (err) {
        console.error(err);
      }
    }

    async function getSites() {
      try {
        const { items } = await listSites({ page: 1, limit: 100 });
        setSites(items);
      } catch (err) {
        console.error(err);
      }
    }

    async function getDeviceTypes() {
      try {
        const { items } = await listDeviceTypes({ page: 1, limit: 100 });
        setDeviceTypes(items);
      } catch (err) {
        console.error(err);
      }
    }

    async function fetchFilters() {
      try {
        setIsFetchingFilters(true);
        await Promise.all([getClients(), getSites(), getDeviceTypes()]);
      } catch (err) {
        console.error(err);
      } finally {
        setIsFetchingFilters(false);
      }
    }

    fetchFilters();
  }, []);

  useEffect(() => {
    async function getAlarms() {
      try {
        setIsFetchingAlarms(true);
        const { items, metadata } = await listAlarms({
          page,
          ...searchParameters,
        });
        setAlarms(items);
        setTotalPage(metadata.pageCount);
      } catch (err) {
        console.error(err);
      } finally {
        setIsFetchingAlarms(false);
      }
    }

    getAlarms();
  }, [page, searchParameters]);

  const filters = (style: string) => (
    <>
      <StandaloneSelect
        className={style}
        placeholder={t(`alarm.list.client.placeholder`)}
        onChange={option => {
          const stringValue = option && option.value;
          setSearchParameters({
            ...searchParameters,
            clientId: stringValue ? stringValue : '',
          });
          setPage(1);
        }}
        options={[
          {
            value: '',
            label: t(`alarm.list.client.all`),
          },
          ...clients.map(client => ({
            value: client.id,
            label: client.name,
          })),
        ]}
      />
      <StandaloneSelect
        className={style}
        placeholder={t(`alarm.list.site.placeholder`)}
        onChange={option => {
          const stringValue = option && option.value;
          setSearchParameters({
            ...searchParameters,
            siteId: stringValue ? stringValue : '',
          });
          setPage(1);
        }}
        options={[
          {
            value: '',
            label: t(`alarm.list.site.all`),
          },
          ...sites.map(site => ({
            value: site.id,
            label: site.name,
          })),
        ]}
      />
      <StandaloneSelect
        className={style}
        placeholder={t(`alarm.list.deviceType.placeholder`)}
        onChange={option => {
          const stringValue = option && option.value;
          setSearchParameters({
            ...searchParameters,
            deviceTypeId: stringValue ? stringValue : '',
          });
          setPage(1);
        }}
        options={[
          {
            value: '',
            label: t(`alarm.list.deviceType.all`),
          },
          ...deviceTypes.map(deviceType => ({
            value: deviceType.id,
            label: deviceType.name,
          })),
        ]}
      />
      <StandaloneSelect
        className={style}
        placeholder={t(`alarm.list.status.placeholder`)}
        onChange={option => {
          const stringValue = option && option.value;
          setSearchParameters({
            ...searchParameters,
            status: stringValue ? stringValue : '',
          });
          setPage(1);
        }}
        defaultValue={{
          label: t(`common.alertStatus.${ALARM_STATUS.OPEN_NOT_ACKNOWLEDGED}`),
          value: ALARM_STATUS.OPEN_NOT_ACKNOWLEDGED,
        }}
        options={[
          {
            value: '',
            label: t(`alarm.list.status.all`),
          },
          ...Object.values(ALARM_STATUS).map((alarmStatus: string) => ({
            value: alarmStatus,
            label: t(`common.alertStatus.${alarmStatus}`),
          })),
        ]}
      />
    </>
  );

  const buttons = (
    <Button
      colorMode="stroke"
      Icon={Export}
      onClick={async () => {
        try {
          await exportCsv(searchParameters);
        } catch (e) {
          alert(t('alarm.list.exportError'));
        }
      }}
    >
      {t('alarm.list.export')}
    </Button>
  );

  return (
    <ConnectedContainer>
      <BasePage className={styles.container} footer={buttons}>
        <div>
          <SectionTitle text={t('alarm.list.title')} />
          <div className={styles.actionContainer}>
            {isMobile && (
              <div
                onClick={() => {
                  setSearchModalOpen(true);
                }}
              >
                <Icon width={32} height={32}>
                  filter
                </Icon>
              </div>
            )}
          </div>
          {!isMobile && (
            <>
              <div className={styles.searchRow}>
                {filters(styles.select)}
                <div className={styles.spacer} />
                <div className={styles.actionContainer}>{buttons}</div>
              </div>
              <Separator className={styles.separator} />
            </>
          )}

          <Loader isLoading={isFetchingFilters || isFetchingAlarms} />

          <NoResults isVisible={!isFetchingAlarms && !alarms.length} />

          {alarms.map(alarm => (
            <Row
              severity={alarm.alert.severity}
              key={alarm.id}
              mobileColumns={[0]}
              rowElements={[
                {
                  text: `${alarm.description}`,
                  subText: `${alarm.alert.name}, ${formatAnomaly(
                    alarm.alert,
                    t,
                  )}`,
                  flex: 3,
                },
                {
                  subText: t(`common.alertStatus.${alarm.status}`),
                  flex: 1,
                },
                {
                  icon: 'chart-pie',
                  // @ts-expect-error Property 'client' does not exist on type 'ISiteV1Response'
                  subText: `${alarm.site.client.name}, ${alarm.site.name}`,
                  flex: 2,
                },
                {
                  icon: 'clock',
                  subText: `${
                    alarm.creationTime
                      ? formatDateLLL(alarm.creationTime)
                      : null
                  }\n> ${superiorTime(alarm.creationTime)}`,
                  flex: 1,
                },
              ]}
              onClick={() =>
                navigate(
                  generatePath(Routes.DeviceView, { id: alarm.deviceId }),
                )
              }
            />
          ))}
          {totalPage > 0 && (
            <Paginator setPage={setPage} page={page} totalPage={totalPage} />
          )}
        </div>
        <Modal
          isOpen={searchModalOpen}
          onRequestClose={() => setSearchModalOpen(false)}
        >
          <div className={styles.filterBy}>{t('user.list.role.filterBy')}</div>

          {filters(styles.selectModal)}

          <div>
            <Button
              className={styles.buttonModal}
              onClick={() => setSearchModalOpen(false)}
            >
              {t('user.list.search')}
            </Button>
          </div>
        </Modal>
      </BasePage>
    </ConnectedContainer>
  );
};

export default AlarmList;
