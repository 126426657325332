import React, { useEffect, useState } from 'react';
import BasePage from '../../../base-page';
import { useTranslation } from 'react-i18next';
import { UserRole } from 'common-active-invest-supervision/dist/src/business/user/types';
import styles from '../../../../ui/styles/add-form.module.scss';
import { ConnectedContainer } from 'business/user/containers/connectedOrNotContainers';
import { useUserContext } from 'business/user/states';
import { Navigate, useNavigate, useParams } from 'react-router';
import { getClient } from 'business/client/services/api';
import { IClientDetailedV1Response } from 'common-active-invest-supervision/dist/src/business/client/api/v1';
import FormClientAdd from '../add/form';
import { Loader } from 'ui/loader';
import { Routes } from 'bootstrap/router';

const ClientModify: React.FC = () => {
  const { t } = useTranslation();
  const { user } = useUserContext();
  const history = useNavigate();
  const { id } = useParams<{ id: string }>();
  const [client, setClient] = useState<IClientDetailedV1Response>();

  useEffect(() => {
    const fetchData = async () => {
      if (id) {
        try {
          const result = await getClient(id);
          setClient(result);
        } catch (err) {
          console.error(err);
        }
      }
    };

    fetchData();
  }, [id]);

  if (
    user &&
    ![UserRole.COMPANY_MANAGER, UserRole.CLIENT_MANAGER].includes(user.role)
  ) {
    return <Navigate replace to={Routes.Home} />;
  }

  return (
    <ConnectedContainer>
      <BasePage className={styles.container}>
        <div>
          <button onClick={() => history(-1)} className={styles.returnButton}>
            &lt; {t('common.return')}
          </button>

          {client ? (
            <FormClientAdd
              initialData={{
                name: client.name,
                customAlertsEnabled: client.customAlertsEnabled,
              }}
              modifyId={id}
            />
          ) : (
            <Loader />
          )}
        </div>
      </BasePage>
    </ConnectedContainer>
  );
};

export default ClientModify;
