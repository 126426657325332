import React, { useEffect, useState } from 'react';
import BasePage from '../../../base-page';
import { useTranslation } from 'react-i18next';
import { UserRole } from 'common-active-invest-supervision/dist/src/business/user/types';
import styles from '../../../../ui/styles/add-form.module.scss';
import { ConnectedContainer } from 'business/user/containers/connectedOrNotContainers';
import { useUserContext } from 'business/user/states';
import { Navigate, useNavigate, useParams } from 'react-router';
import FormUserAdd from '../add/form';
import { getUser } from 'business/user/services/api';
import { IUserDetailedV1Response } from 'common-active-invest-supervision/dist/src/business/user/api/v1';
import { Loader } from 'ui/loader';
import { Routes } from 'bootstrap/router';

export const mapUserRights = {
  [UserRole.GROUP_MANAGER]: [
    UserRole.GROUP_MANAGER,
    UserRole.GROUP_OBSERVER,
    UserRole.COMPANY_MANAGER,
  ],
  [UserRole.GROUP_OBSERVER]: [],
  [UserRole.COMPANY_MANAGER]: [
    UserRole.COMPANY_MANAGER,
    UserRole.COMPANY_OBSERVER,
    UserRole.CLIENT_MANAGER,
    UserRole.CLIENT_OBSERVER,
    UserRole.MAINTAINER,
  ],
  [UserRole.COMPANY_OBSERVER]: [],
  [UserRole.CLIENT_MANAGER]: [
    UserRole.CLIENT_MANAGER,
    UserRole.CLIENT_OBSERVER,
    UserRole.MAINTAINER,
  ],
  [UserRole.CLIENT_OBSERVER]: [],
  [UserRole.MAINTAINER]: [],
};

export function rightToModifyOrDeleteUser(
  connectedUser: UserRole,
  userToModify: UserRole,
) {
  if ((mapUserRights[connectedUser] as UserRole[]).includes(userToModify)) {
    return true;
  }

  return false;
}

const UserModify: React.FC = () => {
  const { t } = useTranslation();
  const { user } = useUserContext();
  const navigate = useNavigate();
  const { id } = useParams<{ id: string }>();
  const [userData, setUserData] = useState<IUserDetailedV1Response>();

  useEffect(() => {
    const fetchData = async () => {
      if (id) {
        try {
          const result = await getUser(id);
          setUserData(result);
        } catch (err) {
          console.error(err);
        }
      }
    };

    fetchData();
  }, [id]);

  if (
    user &&
    userData &&
    !rightToModifyOrDeleteUser(user.role, userData.role)
  ) {
    return <Navigate replace to={Routes.Home} />;
  }

  return (
    <ConnectedContainer>
      <BasePage className={styles.container}>
        <div>
          <button onClick={() => navigate(-1)} className={styles.returnButton}>
            &lt; {t('common.return')}
          </button>

          {userData ? (
            <FormUserAdd
              initialData={{
                firstName: userData.firstName,
                lastName: userData.lastName,
                login: userData.login,
                role: userData.role,
                isActive: userData.isActive,
                email: userData.email || '',
                phone: userData.phone || '',

                companyId: (userData.company && userData.company.id) || '',
                clientId: (userData.client && userData.client.id) || '',
              }}
              modifyId={id}
            />
          ) : (
            <Loader />
          )}
        </div>
      </BasePage>
    </ConnectedContainer>
  );
};

export default UserModify;
