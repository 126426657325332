// Used to ensure value is defined
// Needed with react-router
// See this issue comment https://github.com/remix-run/react-router/issues/8200#issuecomment-1023658322
export function invariant<T>(
  value: T | null | undefined,
  message?: string,
): asserts value is T {
  if (!value) {
    throw new Error(message);
  }
}
