import { axios } from '../../../technical/network';
import {
  ISiteV1Response,
  ISiteDetailV1Response,
} from 'common-active-invest-supervision/dist/src/business/site/api/v1';
import queryString from 'query-string';

export async function getSite(id: string): Promise<ISiteDetailV1Response> {
  const response = await axios.get(`/site/${id}`);

  return response.data;
}

const SITE_POST_REQUEST_FAILED = 'SITE_POST_REQUEST_FAILED';
export async function post(values: {
  clientId: string;
  name: string;
  location: string;
}) {
  const response = await axios.post<ISiteV1Response>('/site', values);
  if (response.status !== 201) {
    throw new Error(SITE_POST_REQUEST_FAILED);
  }

  return response.data;
}

export const SITE_PUT_REQUEST_FAILED = 'SITE_PUT_REQUEST_FAILED';
export async function updateSite(
  id: string,
  values: {
    clientId: string;
    name: string;
    location: string;
  },
): Promise<void> {
  const response = await axios.put<ISiteV1Response>(`/site/${id}`, values);
  if (response.status !== 201) {
    throw new Error(SITE_PUT_REQUEST_FAILED);
  }
}

export async function listSites(params: {
  page?: number;
  limit?: number;
}): Promise<{
  items: ISiteDetailV1Response[];
  metadata: {
    currentPage: number;
    pageCount: number;
  };
}> {
  const filter = {
    page: params.page ? params.page : 1,
    limit: params.limit ? params.limit : undefined,
  };
  const stringifiedQuery = queryString.stringify(filter);

  const response = await axios.get(
    `/sites${stringifiedQuery ? `?${stringifiedQuery}` : ''}`,
  );

  return response.data;
}

const SITE_DELETE_REQUEST_FAILED = 'SITE_DELETE_REQUEST_FAILED';
export async function deleteSite(id: string): Promise<void> {
  const response = await axios.delete(`/site/${id}`);
  if (response.status !== 204) {
    throw new Error(SITE_DELETE_REQUEST_FAILED);
  }
}
