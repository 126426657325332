export enum ALERT_SEVERITY {
  MINOR = 'MINOR',
  MAJOR = 'MAJOR',
  CRITICAL = 'CRITICAL',
}

export enum ALERT_TRIGGER_PERIOD_TYPE {
  OCCURRENCES = 'occurrences',
  MINUTES = 'minutes',
}

export enum ANOMALY_OPERATOR {
  EQUAL = 'EQUAL',
  SUPERIOR = 'SUPERIOR',
  SUPERIOROREQUAL = 'SUPERIOROREQUAL',
  INFERIOR = 'INFERIOR',
  INFERIOROREQUAL = 'INFERIOROREQUAL',
}
