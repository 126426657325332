import React, { useEffect, useState } from 'react';
import BasePage from '../../../base-page';
import { useTranslation } from 'react-i18next';
import { ConnectedContainer } from 'business/user/containers/connectedOrNotContainers';
import styles from 'ui/styles/view.module.scss';
import { SectionTitle } from 'ui/typo/section-title';

import { useNavigate, useParams, generatePath } from 'react-router';
import { Icon } from 'ui/icons';
import { formatDateLLL } from 'technical/date';
import { getUser, deleteUser } from 'business/user/services/api';
import { IUserDetailedV1Response } from 'common-active-invest-supervision/dist/src/business/user/api/v1';
import { rightToModifyOrDeleteUser } from '../modify';
import { useUserContext } from 'business/user/states';
import { Loader } from 'ui/loader';
import {
  redirectWithSuccess,
  redirectWithError,
} from 'technical/flash-message';
import { DeleteButton } from 'ui/button/delete-button';
import { ModifyButton } from 'ui/button/modify-button';
import { Routes } from 'bootstrap/router';
import { invariant } from 'technical/router/query-params';

const UserView: React.FC = () => {
  const { t } = useTranslation();
  const { id } = useParams<{ id: string }>();
  invariant(id);
  const [userData, setUserData] = useState<IUserDetailedV1Response>();
  const { user } = useUserContext();

  const navigate = useNavigate();

  useEffect(() => {
    const fetchData = async () => {
      if (id) {
        try {
          const result = await getUser(id);
          setUserData(result);
        } catch (err) {
          console.error(err);
        }
      }
    };

    fetchData();
  }, [id]);

  const buttons =
    user && userData && rightToModifyOrDeleteUser(user.role, userData.role) ? (
      <>
        <ModifyButton
          className={styles.modifyButton}
          onClick={() => navigate(generatePath(Routes.UserModify, { id }))}
        >
          {t('common.modify')}
        </ModifyButton>
        <DeleteButton
          className={styles.modifyButton}
          onClick={async () => {
            try {
              if (window.confirm(t('user.delete.confirm'))) {
                await deleteUser(String(id));
                redirectWithSuccess('/users', 'user.delete.success');
              }
            } catch (e) {
              redirectWithError('/users', 'user.delete.error');
            }
          }}
        >
          {t('common.delete')}
        </DeleteButton>
      </>
    ) : null;

  return (
    <ConnectedContainer>
      <BasePage className={styles.container} footer={buttons}>
        <div>
          <button onClick={() => navigate(-1)} className={styles.returnButton}>
            &lt; {t('common.return')}
          </button>

          {userData ? (
            <>
              <SectionTitle
                text={`${userData.firstName} ${userData.lastName}`}
              />
              <div className={styles.flex}>
                <div className={styles.spacer} />
                <div className={styles.mainButton}>{buttons}</div>
              </div>

              <div className={styles.panelContainer}>
                <div>
                  <div className={styles.rowIconText} title={t('common.login')}>
                    <Icon width={16} height={16}>
                      account
                    </Icon>
                    <div className={styles.details}>{userData.login}</div>
                  </div>

                  {userData.email && (
                    <div
                      className={styles.rowIconText}
                      title={t('common.email')}
                    >
                      <Icon width={16} height={16}>
                        email
                      </Icon>
                      <div className={styles.details}>{userData.email}</div>
                    </div>
                  )}

                  {userData.phone && (
                    <div
                      className={styles.rowIconText}
                      title={t('common.phone')}
                    >
                      <Icon width={16} height={16}>
                        phone
                      </Icon>
                      <div className={styles.details}>{userData.phone}</div>
                    </div>
                  )}

                  <div className={styles.rowIconText}>
                    <Icon width={16} height={16}>
                      {userData.isActive ? 'check' : 'cross'}
                    </Icon>
                    <div className={styles.details}>
                      {userData.isActive
                        ? t('user.view.activated')
                        : t('user.view.deactivated')}
                    </div>
                  </div>

                  <div className={styles.rowIconText}>
                    <Icon width={16} height={16}>
                      clock
                    </Icon>
                    <div className={styles.details}>
                      {`${t('common.created')} ${formatDateLLL(
                        userData.creationTime,
                      )} \n${t('common.updated')} ${formatDateLLL(
                        userData.updateTime,
                      )}`}
                    </div>
                  </div>
                </div>
              </div>
            </>
          ) : (
            <Loader />
          )}
        </div>
      </BasePage>
    </ConnectedContainer>
  );
};

export default UserView;
