import { ALARM_STATUS } from 'common-active-invest-supervision/dist/src/business/alarm/types';
import { ALERT_SEVERITY } from 'common-active-invest-supervision/dist/src/business/alert/types';
import { IDeviceV1ResponseLight } from 'common-active-invest-supervision/dist/src/business/device/api/v1';

function maxSeverities(severity1: ALERT_SEVERITY, severity2: ALERT_SEVERITY) {
  if (severity1 === ALERT_SEVERITY.MINOR) {
    return severity2;
  }
  if (
    severity1 === ALERT_SEVERITY.MAJOR &&
    ALERT_SEVERITY.CRITICAL === severity2
  ) {
    return severity2;
  }

  return severity1;
}

export function getMaxSeverity(
  device: IDeviceV1ResponseLight,
): ALERT_SEVERITY | null {
  return device.alarms.reduce((maxSeverity, alarm) => {
    if (
      ![ALARM_STATUS.CLOSED, ALARM_STATUS.OPEN_NOT_NOTIFY].includes(
        alarm.status,
      )
    ) {
      if (maxSeverity === null) {
        return alarm.alert.severity;
      }

      return maxSeverities(maxSeverity, alarm.alert.severity);
    }

    return maxSeverity;
  }, null as ALERT_SEVERITY | null);
}
